import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSignaturePad from 'vue-signature-pad'

//createApp(App).use(router).mount('#app')

const app = createApp(App)
app.use(router)
app.use(VueSignaturePad)
app.mount('#app')
