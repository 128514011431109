<script>
export default {
  name: "PageHeader"
}
</script>

<template>
  <div class="page-header">
    <div class="logo-container">
      <img class="logo-image" src="https://melinascheuber.my.site.com/WebForm/file-asset/MelinaScheuber?v=1" alt="Melina Scheuber Logo">
    </div>

  </div>
</template>

<style scoped>

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.logo-container {
  height: auto;
  max-height: 150px;
  width: auto;
  max-width: 100%;
}

.logo-image {
  width: 100%;
  height: auto;
  max-height: 70px;
  object-fit: contain;
}

</style>