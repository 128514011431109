<!-- SignatureComponent.vue -->
<template>
  <div class="signature-component">
    <div class="signature-box" @click="openModal">
      <img v-if="modelValue" :src="modelValue" alt="Signature" />
      <span v-else>zum Unterschreiben hier klicken</span>
    </div>

    <Teleport to="body">
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <!--<h3>Create Your Signature</h3>-->
          <h3>Ihre Unterschrift erstellen</h3>
          <div v-if="!signatureMethod" class="signature-method-selection">
            <!--<button @click="selectMethod('draw')" class="method-button">Draw Signature</button>-->
            <button @click="selectMethod('draw')" class="method-button">Unterschrift zeichnen</button>
            <!--<button @click="selectMethod('type')" class="method-button">Type Signature</button>-->
            <button @click="selectMethod('type')" class="method-button">Typ-Signatur</button>
          </div>

          <div v-if="signatureMethod === 'draw'" style="text-align: center;">
            <canvas ref="signaturePad" width="400" height="200"></canvas>
            <div class="button-group">
              <button @click="clearSignature" class="secondary-button">Löschen</button>
            </div>
          </div>

          <div v-if="signatureMethod === 'type'">
            <input v-model="typedSignature" placeholder="Type your signature" class="signature-input" />
            <div class="typed-signature-preview" ref="previewDiv">
              {{ typedSignature }}
            </div>
          </div>

          <div class="modal-buttons">
           <!--<button @click="saveSignature" class="primary-button">Save</button>-->
            <button @click="saveSignature" class="primary-button">Speichern</button>
           <!-- <button @click="closeModal" class="secondary-button">Cancel</button>-->
            <button @click="closeModal" class="secondary-button">Abbrechen</button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import SignaturePad from 'signature_pad';

defineProps({
  modelValue: String,
});

const emit = defineEmits(['update:modelValue']);

const showModal = ref(false);
const signaturePadInstance = ref(null);
const signaturePad = ref(null);
const signatureMethod = ref(null);
const typedSignature = ref('');
const previewDiv = ref(null);

const openModal = () => {
  showModal.value = true;
  signatureMethod.value = null;
  typedSignature.value = '';
};

const closeModal = () => {
  showModal.value = false;
  signatureMethod.value = null;
};

const selectMethod = (method) => {
  signatureMethod.value = method;
  if (method === 'draw') {
    setTimeout(() => {
      if (signaturePad.value) {
        signaturePadInstance.value = new SignaturePad(signaturePad.value, {
          backgroundColor: 'rgb(255, 255, 255)',
          penColor: 'rgb(0, 0, 0)'
        });
      }
    }, 0);
  }
};

const clearSignature = () => {
  if (signaturePadInstance.value) {
    signaturePadInstance.value.clear();
  }
};

const PADDING = 20; // Add padding constant
const adjustFontSize = () => {
  if (previewDiv.value) {
    const containerWidth = previewDiv.value.offsetWidth - (2 * PADDING);
    const containerHeight = previewDiv.value.offsetHeight - (2 * PADDING);
    let fontSize = 200; // Start with a large font size
    let textWidth, textHeight;

    previewDiv.value.style.fontSize = `${fontSize}px`;
    previewDiv.value.style.padding = `${PADDING}px`;

    do {
      fontSize--;
      previewDiv.value.style.fontSize = `${fontSize}px`;
      textWidth = previewDiv.value.scrollWidth - (2 * PADDING);
      textHeight = previewDiv.value.scrollHeight - (2 * PADDING);
    } while ((textWidth > containerWidth || textHeight > containerHeight) && fontSize > 12);

    // Fine-tune to maximize size while staying within bounds
    while (textWidth <= containerWidth && textHeight <= containerHeight && fontSize < 200) {
      fontSize++;
      previewDiv.value.style.fontSize = `${fontSize}px`;
      textWidth = previewDiv.value.scrollWidth - (2 * PADDING);
      textHeight = previewDiv.value.scrollHeight - (2 * PADDING);
    }

    // Step back once to ensure it fits
    fontSize--;
    previewDiv.value.style.fontSize = `${fontSize}px`;
  }
};

const saveSignature = () => {
  let signatureData;
  if (signatureMethod.value === 'draw') {
    if (signaturePadInstance.value && !signaturePadInstance.value.isEmpty()) {
      signatureData = signaturePadInstance.value.toDataURL();
    } else {
      alert('Please provide a signature');
      return;
    }
  } else if (signatureMethod.value === 'type') {
    if (typedSignature.value) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 400;
      canvas.height = 200;
      
      // Use the same font size and family as in the preview
      const computedStyle = window.getComputedStyle(previewDiv.value);
      ctx.font = `${computedStyle.fontSize} ${computedStyle.fontFamily}`;
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      // Draw the text to fill the canvas with padding
      ctx.fillText(typedSignature.value, canvas.width / 2, canvas.height / 2, canvas.width - (2 * PADDING));
      signatureData = canvas.toDataURL();
    } else {
      alert('Please type your signature');
      return;
    }
  } else {
    alert('Please select a signature method');
    return;
  }
  emit('update:modelValue', signatureData);
  closeModal();
};

// Cleanup and setup
onMounted(() => {
  watch(() => showModal.value, (newVal) => {
    if (!newVal && signaturePadInstance.value) {
      signaturePadInstance.value.off();
    }
  });

  watch(() => typedSignature.value, () => {
    adjustFontSize();
  });
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

.signature-component {
  //font-family: 'Cedarville Cursive', cursive;
  color: #333;
}

.signature-box {
  border-radius: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signature-box:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.signature-box img {
  max-width: 100%;
  max-height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fffcf7;
  padding: 20px;
  border-radius: 0;
  width: 450px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

h3 {
  color: #bf6849;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

canvas {
  border: 1px solid #bf6849;
  border-radius: 0;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.signature-method-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.method-button {
  flex: 1;
  padding: 10px;
  background-color: #bf6849;
  color: #ffffff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Arial, sans-serif;
  margin: 4px;
}

.method-button:hover {
}

.signature-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid #bf6849;
}

.typed-signature-preview {
  height: 200px;
  width: 400px;
  border: 1px solid #bf6849;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Cedarville Cursive', cursive;
  background-color: #fff;
  color: #000;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 10px;
  white-space: nowrap;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Arial, sans-serif;
}

.primary-button {
  background-color: #bf6849;
  color: #ffffff;
}

.primary-button:hover {
  background-color: #8e4e37;
}

.secondary-button {
  color: #bf6849;
}

.secondary-button:hover {
  background-color: #cacaca;
}
</style>