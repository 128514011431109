<script>
// Import necessary components and classes
import PageHeader from '@/components/PageHeader.vue';
import {
  FlowForm,
  QuestionModel,
  QuestionType,
  ChoiceOption,
  LanguageModel, /*MatrixColumn, MatrixRow*/
} from '@ditdot-dev/vue-flow-form'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

export default {
  name: 'example-1',
  components: {
    FlowForm
  },
  showErrorMessage: false,
  showContent: false,
  errorMessage: '',
  data() {
    return {
      language: new LanguageModel({
        continue: 'weiter',
        pressEnter: '',
        submitText:'Absenden',
        percentCompleted: ':percent% beantwortet',
        multipleChoiceHelpTextSingle: 'Wählen Sie eine Antwort',
      
        // Your language definitions here (optional).
        // You can leave out this prop if you want to use the default definitions.
      }), 
      questions: [],
      conractId: '',
      loading: true,
      isLoading: true,
      error: null
    }
  },
  created() {
    this.fetchQuestions();
  },

  methods: {

    fetchQuestions() {
      const recordId = this.$route.query.id;
      console.log('recordId -> : ' + recordId);

      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const apiUrl = `${baseUrl}/quiz?id=${recordId}`;

      // Replace with your Salesforce org's URL
      //const apiUrl = 'https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/quiz?id=' + recordId;

      console.log('Fetching questions from:', apiUrl);

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN_HERE', // Replace with actual authentication
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('Response status:', response.status);
          if (!response.ok) {
            this.loading = false;
            this.showContent = false;
            this.showErrorMessage = true;
            this.errorMessage = 'The URL you provided is invalid or has expired.';
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.loading = false;
          this.showContent = true;
          console.log('Received data:', data);
          if (data && data.data && Array.isArray(data.data.questions)) {
            this.questions = data.data.questions.map(q => {
              const convertedQuestion = this.convertToQuestionModel(q);
              console.log('Converted question:', convertedQuestion);
              return convertedQuestion;
            });
            this.conractId = data.data.contractId;
            console.log('Total questions processed:', this.questions.length);
            console.log('questions', this.questions);
          } else {
            throw new Error('Unexpected data format received');
          }
        })
        .catch(error => {
          this.loading = false;
          console.error('Error fetching questions:', error);
          this.error = 'Failed to load questions. Please try again later.';
          this.showContent = false;
          this.showErrorMessage = true;
          this.errorMessage = 'The URL you provided is invalid or has expired.';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    convertToQuestionModel(question) {
      const questionModel = new QuestionModel({
        id: question.id,
        tagline: question.tagline,
        title: question.title,
        //subtitle: question.subtitle,
        type: QuestionType[question.type],
        multiple: question.multiple,
        required: question.required,
        max: question.max,
        labelLeft: question.labelLeft,
        labelRight: question.labelRight,
        content: question.content,
        description: question.description
      });

      if (question.options) {
        questionModel.options = question.options.map(opt => new ChoiceOption(opt));
      }

      return questionModel;
    },

    onComplete(completed, questionList) {
      // This method is called whenever the "completed" status is changed.
      console.log(questionList);
      this.completed = completed
    },


    /* eslint-disable-next-line no-unused-vars */
    onSubmit(questionList) {
      // This method will only be called if you don't override the
      // completeButton slot.
      this.sendData()
    },

    sendData() {
      // Set `submitted` to true so the form knows not to allow back/forward
      // navigation anymore.
      //this.$refs.flowform.submitted = true

      this.submitted = true

      /* eslint-disable-next-line no-unused-vars */
      const data = this.getData();
      console.log('Data to be sent:', data);

      // Here you would typically send the data to your server
      // For example, using fetch:
      /*
      fetch('your-api-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(result => console.log('Success:', result))
      .catch(error => console.error('Error:', error));
      */
     
      const recordId = this.$route.query.id;
      console.log('recordId -> : ' + recordId);

      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const apiUrl = `${baseUrl}/quiz?id=${recordId}`;

      //const apiUrl = 'https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/quiz?id=' + recordId;
      console.log('Send data url : ', apiUrl);

      this.loading = true;

      fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN_HERE', // Replace with actual authentication
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('Response status:', response.status);
          if (!response.ok) {
            this.loading = false;
            this.showErrorMessage = true;
            this.errorMessage = 'An error occurred while submitting the form.';
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.loading = false;
          this.showContent = false;
          console.log('data posted :', data);
          this.$router.push({path: '/success-page', query: {id: this.conractId}});
        })
        .catch(error => {
          this.loading = false;
          console.error('Error fetching questions:', error);
          this.error = 'Failed to load questions. Please try again later.';
          this.showErrorMessage = true;
          this.errorMessage = 'An error occurred while submitting the form.';
        })
        .finally(() => {
          this.loading = false;
          //this.isLoading = false;
        });


    },

    getData() {

      const data = this.questions.map(question => ({
        questionId: question.id,
        answer: this.formatAnswer(question.answer)
      }));

      console.log('Formatted data:', data);
      return data;
    },

    formatAnswer(answer) {
      if (Array.isArray(answer)) {
        return answer.join(', ');
      }
      return answer !== null ? answer : '';
    },

  }

}
</script>

<template>
  
  <div>
    <header v-if="showContent" style="" class="header">
<!--      <div class="f-container">
        <button @click="getData"> Show JSON</button>
      </div>-->
      <div class="logo">
        <img src="https://images.squarespace-cdn.com/content/v1/6650a04a5d3ab928a99581ef/96758329-f48b-4c87-a976-4a32becd9af8/MS+Wordmark.png?format=1500w" alt="Logo">
      </div>
    </header>



    <div v-if="showErrorMessage" style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
      <div>
        <i class="pi pi-exclamation-circle" style="font-size: 3rem;color: #CB0015;"></i>
      </div>
      <div>
        {{ errorMessage }}
      </div>
    </div>

    <div v-if="loading" class="loading-container">
      <div class="loading-spinner"></div>
    </div>


    <!--<div v-if="isLoading" class="loading">
      Loading questions...
    </div>
      
      <div v-else-if="error" class="error">
      {{ error }}
    </div>-->

    <flow-form class=""
      v-else
      v-bind:questions="questions"
      v-bind:language="language"
      v-on:complete="onComplete"
      v-on:submit="onSubmit"
    >
    <template v-slot:complete>
      <div class="f-section-wrap">
        <p>
          <span class="fh2"></span>
          <span class="f-section-text">Ihr Risikoprofil wurde erfolgreich berechnet. Durch Klicken auf "Absenden" kehren Sie zum Hauptmenü zurück, wo Sie Ihr ausgefülltes und berechnetes Risikoprofil einsehen und unterschreiben können.
          </span>
        </p>
        <p class="f-description"></p>
      </div>  
    </template>
    </flow-form>
  </div>
  
</template> 



<style>
/* Import Vue Flow Form base CSS */
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
/* Import one of the Vue Flow Form CSS themes (optional) */
/*@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';*/
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
@import "../assets/vue-flow-form.theme-arrify.css";

/*.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}*/

/*.container{
  font-family: Avenir;
}*/

.o-btn-action{
  text-transform: capitalize !important;
}

.f-text {
  font-weight: bold;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.error {
  color: red;
}

header.vff-header+.vff {
  margin-top: 4vh !important;
}
</style>
