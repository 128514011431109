<template>
  <div class="card mb-3" :class="cardClass">
    <div class="card-body position-relative d-flex flex-column align-items-center justify-content-center">
<!--      <h5 class="card-title text-center">
        Sign the Contract
      </h5>-->
      <p class="card-text"></p>
      <font-awesome-icon :icon="statusIcon" class="mb-3" :class="[iconClass, 'fa-4x']" />

      <template v-if="!isCompleted">
        <router-link
            :to="{ path: '/sign-contract', query: { id: contractId } }"
            class="btn btn-light ms-btn"
        >
          <!--Sign Contract-->
          Vertrag unterschreiben
        </router-link>
      </template>
      <template v-else>
        <p class="mb-0 text-center">
          <!--Contract signed successfully!-->
          Vertrag erfolgreich unterschrieben!
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { faCheckCircle, faExclamationCircle, faSpinner, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCheckCircle, faExclamationCircle, faSpinner, faFileSignature);

export default {
  name: 'SignContractSection',
  components: {
    FontAwesomeIcon
  },
  props: {
    isContractSigned: {
      type: Boolean,
      required: true
    },
    contractId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();

    const isCompleted = computed(() => props.isContractSigned);
    const statusIcon = computed(() => isCompleted.value ? ['fas', 'check-circle'] : ['fas', 'file-signature']);
    const iconClass = computed(() => isCompleted.value ? 'text-tc' : 'text-white');
    const cardClass = computed(() => isCompleted.value ? 'bg-success text-tc' : 'bg-primary text-white');

    return {
      isCompleted,
      statusIcon,
      iconClass,
      cardClass,
    };
  } 
};
</script>

<style scoped>

</style>