<template>
  <div class="card mb-3" :class="cardClass">
    <div class="card-body position-relative d-flex flex-column align-items-center justify-content-center">
      <font-awesome-icon :icon="statusIcon" class="mb-3" :class="[iconClass, 'fa-4x']" />

      <template v-if="isCompleted">
        <p class="mb-0 text-center text-tc">
          Risikoprofil erfolgreich berechnet!
        </p>
      </template>
      <template v-else-if="isUnlocked">
          <router-link
              :to="{ path: '/quiz', query: { id: riskProfileId } }"
              class="btn btn-light ms-btn"
          >
          <!--Calculate Risk Profile-->
          Risikoprofil ermitteln
        </router-link>



      </template>
      <template v-else>
        <p class="mb-0 text-center text-tc">
          {{ lockedText }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { faCheckCircle, faCalculator, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCheckCircle, faCalculator, faLock);

export default {
  name: 'CalculateRiskProfileSection',
  components: {
    FontAwesomeIcon
  },
  props: {
    isContractSigned: {
      type: Boolean,
      required: true
    },
    isQuizCompleted: {
      type: Boolean,
      required: true
    },
    riskProfileId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();

    const isUnlocked = computed(() => props.isContractSigned);
    const isCompleted = computed(() => props.isContractSigned && props.isQuizCompleted);

    const statusIcon = computed(() => {
      if (isCompleted.value) return ['fas', 'check-circle'];
      if (isUnlocked.value) return ['fas', 'calculator'];
      return ['fas', 'lock'];
    });

    // const iconClass = computed(() => 'text-white');

    const iconClass = computed(() => {
      if (isCompleted.value) return 'text-tc';
      if (isUnlocked.value) return 'text-white';
      return 'text-tc';
    });

    const cardClass = computed(() => {
      if (isCompleted.value) return 'bg-success text-white';
      if (isUnlocked.value) return 'bg-primary text-white';
      return 'bg-secondary text-white';
    });

    const lockedText = computed(() => 'Bitte unterschreiben Sie den Vertrag, um diesen Bereich freizuschalten.');

    return {
      isUnlocked,
      isCompleted,
      statusIcon,
      iconClass,
      cardClass,
      lockedText
    };
  }
};
</script>

<style scoped>

</style>