<script>

export default {
  name: "PageFooter",
  props: ['pageCount', 'total'],
  setup(props) {
    console.log(props.pageCount)
  }
}
</script>


<template>

  <div class="page-footer">
    Seite
    <br/>
    <span>{{ pageCount }} / {{ total }}</span>
    </div>
</template>

<style scoped>

</style>