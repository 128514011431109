<template>
  <div id="app">
    <div v-if="loading" class="loading-container">
      <div class="loading-spinner"></div>
    </div>

    <div v-if="showErrorMessage" style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
      <div>
        <i class="pi pi-exclamation-circle" style="font-size: 3rem;color: #CB0015;"></i>
      </div>
      <div>
        {{ errorMessage }}
      </div>
    </div>

    <!--<div v-else-if="showSuccessMessage" style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
      <div>
        <i class="pi pi-check-circle" style="font-size: 3rem;color: green;"></i>
      </div>
      <div>
        <p>Form submitted successfully!</p>
      </div>
    </div>-->

    <div v-if="showContent && !loading">
      <div class="page">
        <page-header/>

        <p class="heading-font-style" style="margin-bottom: 0pt;">Anlageberatungsvertrag</p>


        <table class="fontStyle" style="width:30%">
          <tr>
            <td style="font-weight: bold;text-align: left;">"Vertrag"</td>
          </tr>
          <tr>
            <td style="text-align: left;width: 5%;">vom {{ this.contractData.contractDate }}</td>
          </tr>
        </table>

        <table class="fontStyle" style="width:100%;">
          <tr>
            <td class="section-padding" style="text-align: left; font-size: 12px;padding-top: 0.5in;">zwischen</td>
          </tr>
          <tr v-if="!isPerson2">
            <td style="text-align: left;">
              {{ this.contractData.person1.contactFirstname }} {{ this.contractData.person1.contactLastName }}
              <!--<br/>
                {{ this.contractData.person1.address }}-->
            </td>
          </tr>
          <tr v-if="isPerson2">
            <td style="text-align: left;">
              {{ this.contractData.person1.contactFirstname }} {{ this.contractData.person1.contactLastName }}
                <span v-if="(contractData.person1.contactFirstname || contractData.person1.contactLastName) && (contractData.person2.contactFirstname || contractData.person2.contactLastName)">
                  & 
                </span>
                {{ this.contractData.person2.contactFirstname }} {{ this.contractData.person2.contactLastName }}
                <!--<br/>
                {{ this.contractData.person1.address }}-->
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">{{ this.contractData.person1.street }}</td>
          </tr>
          <tr>
            <td style="text-align: left;">{{ this.contractData.person1.zipCode }} {{ this.contractData.person1.city }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">{{ this.contractData.person1.country }}</td>
          </tr>
          <tr>
            <td style="text-align: left;font-weight: bold;">"Kundschaft"</td>
          </tr>
        </table>

        <table title="zwischen" class="fontStyle" style="width:40%;">
          <tr>
            <td class="section-padding" style="text-align: left; font-size: 12px;padding-top: 0.5in;">und</td>
          </tr>
          <tr>
            <td style="text-align: left;">Melina Scheuber AG</td>
          </tr>
          <tr>
            <td style="text-align: left;">Zugerstrasse 32</td>
          </tr>
          <tr>
            <td style="text-align: left;">6340 Baar</td>
          </tr>
          <tr>
            <td style="text-align: left;">Schweiz</td>
          </tr>
          <tr>
            <td style="text-align: left;font-weight: bold;">"Anlageberaterin"</td>
          </tr>
        </table>

        <p class="section-padding" style="text-align: left;padding-top: 0.5in;">Kundschaft und Anlageberaterin je eine "<span
            class="text-bold">Partei</span>" zusammen gemeinsam die "<span class="text-bold">Parteien</span>"</p>

        <table class="fontStyle" style="width:40%;padding-top:0.5in;">
          <tr>
            <td style="font-size: 12px;text-align: left;padding-top: 0.5in;">betreffend</td>
          </tr>
          <tr>
            <td style="text-align: left;">Auftrag zur Anlageberatung</td>
          </tr>
        </table>

        <PageFooter page-count="1" :total="total"/>
      </div>

      <div class=" page-container">

      <div class="page" style="text-align: left;">
        <page-header/>
        <div>
          <p class="text-bold" style="margin-bottom: 3pt;">Inhaltsverzeichnis</p>
          <table class="horizontal_dotted_line" width="100%">
            <tr v-for="(item, index) in this.contractData.tableContent" :key="index">
              <td v-if="item.indent === 0" class="line">{{ item.text }}</td>
              <td v-else-if="item.indent === 1" :style="{ 'padding-left': '25px' }">{{ item.text }}</td>
              <td style="text-align: right;padding-right: 20px;">{{ item.page }}</td>
            </tr>
          </table>
        </div>
        <PageFooter page-count="2" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="text-bold padding-top">1.</td>
            <td class="text-bold padding-left padding-top">Vertragsgegenstand und Leistungsumfang</td>
          </tr>

          <tr>
            <td class="text-bold padding-top" style="">1.1.</td>
            <td class="text-bold padding-left padding-top">Auftrag zur Anlageberatung</td>
          </tr>
          <tr>
            <td class=""></td>
            <td class="padding-left">
              <p>
                Die Kundschaft beauftragt die Anlageberaterin, Anlageberatungsdienstleistungen gemäss den in diesem
                Vertrag sowie den weiteren Vertragsbestandteilen vereinbarten Bedingungen gegen Entrichtung einer
                Beratungsgebühr zu erbringen.
                Gegenstand dieses Vertrags bildet der Auftrag der Kundschaft an die Anlageberaterin, individuelle
                Anlageberatungsdienstleistungen zu erbringen ("<span class="text-bold">Anlageberatung</span>").
              </p>
                <p>
                Dieser Vertrag bezieht sich auf die in der eingereichten Steuererklärung aufgeführten Bank- und
                Depotbeziehungen verwahrten Vermögenswerte, das Säule 3a Vermögen und das ausserhalb einer Pensionskasse
                geführte Freizügigkeitsvermögen der Kundschaft ("<span class="text-bold">Beratungsportfolio</span>").
              </p>
                <p>
                Die Kundschaft verwaltet die Bankguthaben, Effekten und Finanzinstrumente im Beratungsportfolio
                selbständig und trifft sämtliche Anlageentscheide in eigener Verantwortung.
              </p>
            </td>
          </tr>

          <tr>
            <td class="text-bold padding-top" style="">1.2.</td>
            <td class="text-bold padding-left padding-top">Grundlagen der Anlageberatung</td>
          </tr>
          <tr>
            <td class="padding-top" style="">1.2.1.</td>
            <td class="padding-left padding-top">Kundensegmentierung</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
              Die Anlageberaterin ordnet die Kundschaft einem Kundensegment gemäss dem Finanzdienstleistungsgesetz
              ("<span class="text-bold">FIDLEG</span>"; SR 950.1) zu. Die Einzelheiten sind in <span class="text-bold">Anhang 1</span>
              geregelt.
            </p>
            </td>
          </tr>
          <tr>
            <td class="padding-top" style="">1.2.2.</td>
            <td class="padding-left padding-top">Kenntnisse und Erfahrungen der Kundschaft</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Anlageberaterin klärt die Kenntnisse und Erfahrungen der Kundschaft hinsichtlich der von der
                Kundschaft im Beratungsportfolio eingesetzten bzw.
                hierfür zu erwerbenden Kategorien von Finanzinstrumenten bei Vertragsbeginn ab und dokumentiert die
                diesbezüglichen Angaben der Kundschaft in einem separaten Anhang (<span class="text-bold">Anhang 1</span>),
                der einen integrierenden Vertragsbestandteil bildet. Bei der Erbringung der Anlageberatung stützt sich die
                Anlageberaterin auf diese Angaben der Kundschaft ab.
                Die Kundschaft bestätigt die Richtigkeit und Vollständigkeit ihrer Angaben und verpflichtet sich, der
                Anlageberaterin alle wesentlichen Änderungen der Verhältnisse mitzuteilen.
                </p>
            </td>
          </tr>

          <tr>
            <td class="text-bold padding-top" style="">1.3.</td>
            <td class="text-bold padding-left padding-top">Gegenstand der Anlageberatung</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Anlageberatung kann auf Initiative der Kundschaft oder auf Initiative der Anlageberaterin erfolgen.
                Sie besteht in konkreten Anlage- und
                Desinvestitionsempfehlungen der Anlageberaterin gemäss Ziff. 1.3.1 und
                1.3.2 an die Kundschaft und basiert auf internen Wirtschafts- und
                Investmentanalysen und den im Zeitpunkt der
                Beratung verfügbaren Marktdaten sowie der vereinbarten Anlagestrategie. Soweit von der Anlageberaterin
                nicht ausdrücklich zugesichert,
                ist die Anlageberatung aufgrund ihrer Marktabhängigkeit nur für einen kurzen Zeitraum gültig.
              </p>
              <p>
                Die Anlageberaterin begrenzt ihre Wirtschafts- und Investmentanalysen nach eigenem Ermessen auf eine
                bestimmte Anzahl von Finanzinstrumenten ("<span class="text-bold">Anlageuniversum</span>") gemäss <span class="text-bold">Anhang 2</span>. Sie erteilt aus eigener Initiative keine Anlageberatung in Bezug
                auf Finanzinstrumente ausserhalb des Anlageuniversums.
                </p>
                <p>
                Die Anlageberaterin ist nicht verpflichtet, nicht öffentlich bekannte Informationen über Finanzinstrumente
                und Emittenten zu erlangen. Ferner ist die Anlageberaterin nicht verpflichtet, ihr vorliegende, aber nicht
                öffentlich bekannte Informationen über Finanzinstrumente oder Emittenten bei der Anlageberatung zu
                berücksichtigen.
                </p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="3" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="padding-top" style="">1.3.1.</td>
            <td class="padding-left padding-top">Anlageberatung auf Initiative der Kundschaft</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Kundschaft hat das Recht, die Anlageberaterin jederzeit zu konsultieren, um Anlage- und
                Desinvestitionsempfehlungen einzuholen.
              </p>
                <p>
                Die Anlageberaterin ist berechtigt, aber nicht verpflichtet, Anlageberatungen für Finanzinstrumente
                ausserhalb des Anlageuniversums zu leisten.
                Lehnt sie das Leisten einer Anlageberatung im Einzelfall ab, weist sie die Kundschaft ausdrücklich darauf
                hin.
                </p>
            </td>
          </tr>
          <tr>
            <td class="padding-top" style="">1.3.2.</td>
            <td class="padding-left padding-top">Anlageberatung auf Initiative der Anlageberaterin</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Sofern die Kundschaft dies wünscht, kann die Anlageberaterin der Kundschaft zudem nach freiem Ermessen von
                Zeit zu Zeit gestützt auf ihre internen Wirtschafts- und Investmentanalysen aus eigener Initiative
                zusätzliche Anlageberatung in Form von Anlageempfehlungen in Bezug auf Finanzinstrumente aus dem
                Anlageuniversum leisten.
                Solch zusätzliche Anlageberatung vermittelt der Kundschaft keinen Anspruch darauf, diese Beratung auch in
                Zukunft zu erhalten.
              </p>
              <p>
                Das Zusenden von allgemeinen Kundeninformationen stellt keine Anlageberatung dar.
                </p>
                <p>
                Anlageberatung auf Initiative der Anlageberaterin besteht ausschliesslich in Form von Anlageempfehlungen.
                Die Anlageberaterin erteilt der Kundschaft auf eigene Initiative keine Desinvestitionsempfehlungen.
                </p>

              <div style="display: flex; align-items: baseline; position: relative;margin-top: -3pt;">
                <p style="padding-right: 4px;">Die Kundschaft wünscht Anlageberatung auf Initiative der Anlageberaterin:</p>
                <!--<input type='text' v-model="this.contractData.advisorInitiativeInfoRequested">-->
                <select v-model="this.contractData.advisorInitiativeInfoRequested" :id="`option-${index}`">
                  <option value="">Wählen Sie eine Option</option>
                  <option v-for="opt in this.dropDownOptions" :key="opt" :value="opt">{{ opt }}</option>
                </select>
                <div v-if="errorMessages.advisorInitiativeInfoRequested" class="error-message">
                  {{ errorMessages.advisorInitiativeInfoRequested }}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="text-bold" style="">1.4.</td>
            <td class="text-bold padding-left">Mitwirkungspflicht der Kundschaft</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Kundschaft ist verpflichtet, alle notwendigen Mitwirkungsleistungen zu erbringen, damit die
                Anlageberaterin die Leistung gemäss diesem Vertrag erbringen kann. Dies betrifft insbesondere die
                Überlassung von Daten und Informationen.
                Kommt die Kundschaft ihren Mitwirkungspflichten trotz einmaliger Aufforderung der Anlageberaterin nicht
                nach, ist die Anlageberaterin von jeglicher Leistungspflicht befreit.
                Führt die Anlageberaterin dennoch die Leistungen durch, stellt sie diesen Aufwand der Kundschaft zu den in
                diesem Vertrag vereinbarten Konditionen in Rechnung.<br/>
                </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">1.5.</td>
            <td class="text-bold padding-left padding-top">Ausgeschlossene Dienstleistungen</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Anlageberaterin erbringt im Rahmen dieses Vertrags keine Vermögensverwaltung und tätigt entsprechend
                keine Geschäfte für die Kundschaft. Die Kundschaft ist allein dafür verantwortlich zu entscheiden, ob sie den Anlage- und
                Desinvestitionsempfehlungen der Anlageberaterin nachkommt.
              </p>
                <p>
                Die Anlageberaterin ist nicht verpflichtet, das Portfolio der Kundschaft oder einzelne darin enthaltene
                Finanzinstrumente zu überwachen. Sie ist insbesondere auch nicht verpflichtet, die Kundschaft nach einer erfolgten Investition in Bezug auf Ereignisse oder Entwicklungen im Zusammenhang mit
                den erworbenen Finanzinstrumenten zu warnen oder konkrete Desinvestitionsempfehlungen abzugeben, und zwar
                auch dann nicht, wenn ein dringendes Tätigwerden erforderlich wäre.
                </p>
                
                <p>
                Im Zusammenhang mit den im Rahmen dieses Vertrags getätigten Anlagegeschäften erbringt die Anlageberaterin
                keine Rechts- und Steuerberatung und die Anlageberaterin ist auch nicht
                verpflichtet, bei der Erbringung der Anlageberatung, die steuerliche Situation der Kundschaft zu
                berücksichtigen. Die Anlageberaterin empfiehlt der Kundschaft, vor Anlageentscheidungen eine unabhängige
                Rechts- und Steuerberatung einzuholen.
                </p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="4" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="text-bold padding-top" style="">1.6.</td>
            <td class="text-bold padding-left padding-top">Allgemeine Kundeninformationen</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
              Sofern die Kundschaft dies wünscht, kann die Anlageberaterin der Kundschaft nach freiem Ermessen von Zeit
              zu Zeit allgemeine Kundeninformationen zu bestimmten Finanzinstrumenten zukommen lassen. Die Kundschaft
              nimmt zur Kenntnis und ist damit einverstanden, dass solche allgemeinen Kundeninformationen keine
              Anlageberatung und auch keine Offerte oder Aufforderung der Anlageberaterin zum Erwerb von
              Finanzinstrumenten darstellen.
              </p>

              <div style="display: flex; align-items: baseline; position: relative;margin-top: -3pt;">
                <p style="padding-right: 4px;">Die Kundschaft wünscht allgemeine Kundeninformationen der Anlageberaterin:</p>
                <!--<input type='text' v-model="this.contractData.advisorInfoRequested">-->
                <select v-model="this.contractData.advisorInfoRequested" :id="`option-${index}`">
                  <option value="">Wählen Sie eine Option</option>
                  <option v-for="opt in this.dropDownOptions" :key="opt" :value="opt">{{ opt }}</option>
                </select>
                <div v-if="errorMessages.advisorInfoRequested" class="error-message">
                  {{ errorMessages.advisorInfoRequested }}
                </div>

              </div>
            </td>
          </tr>

          <tr>
            <td class="text-bold" style="">2.</td>
            <td class="text-bold padding-left">Risikoaufklärung</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Kundschaft nimmt zur Kenntnis, dass sich aus der Wertentwicklung eines Finanzinstruments oder einer
              Anlageklasse in der Vergangenheit keine Schlüsse auf dessen/deren Wertentwicklung in der Zukunft ziehen
              lassen. Die Kundschaft nimmt weiter zur Kenntnis und erklärt sich damit einverstanden, dass die
              Anlageberaterin keine Zusicherungen betreffend den Anlageerfolg, die Performance oder Verlustvermeidung
              einer Anlageklasse, eines Finanzinstruments oder einer Anlagestrategie abgeben kann und darf.
              </p>
              <p>
              Die Kundschaft bestätigt, dass sie die Broschüre
              "<a href="https://www.swissbanking.ch/_Resources/Persistent/8/c/6/e/8c6eaad9339b1ce68b7098f806f46ffa85e6618c/SBVg_Risiken_im_Handel_mit_Finanzinstrumenten_2023_DE.pdf"
                target="_blank">Risiken im Handel mit Finanzinstrumenten</a>" (<span class="text-bold">Anhang 3</span>) erhalten, gelesen und verstanden hat.
              </p>
              <p>
              Die Kundschaft bestätigt, dass ihr vor Unterzeichnung dieses Vertrags die Risiken der Anlageberatung
              gemäss <span class="text-bold">Anhang 3</span> ausführlich erläutert wurden, sie diese verstanden hat und
              diese akzeptiert.
              </p>
              <p>
              Die Anlageberaterin empfiehlt der Kundschaft und diese verpflichtet sich, die Broschüre "Risiken im Handel
              mit Finanzinstrumenten" (in der jeweils aktuellen Version) sowie die instrumentenspezifischen Dokumente
              (z.B. Prospekt, Zeichnungsformular, Private Placement Memorandum, Key Investor Information Document) vor
              jeder einzelnen Anlageentscheidung aufmerksam zu lesen und die Anlageberaterin bei Fragen zu kontaktieren.
            </p>
            <p>
              Im Rahmen der Anlageberatung nimmt die Anlageberaterin anhand des <span class="text-bold">Anhangs 1</span>
              eine Eignungs- und Angemessenheitsprüfung vor. Fehlt der Kundschaft das Verständnis für wesentliche
              Risiken des konkreten Finanzinstruments, klärt die Anlageberaterin sie darüber auf oder rät ihr vom Kauf
              ab.</p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">3.</td>
            <td class="text-bold padding-left padding-top">Vermeidung von Interessenkonflikten</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>Die Anlageberaterin hat angemessene organisatorische Massnahmen getroffen, um Interessenkonflikte zwischen
              ihr, ihren Angestellten oder von ihr beigezogene Dritte und der Kundschaft zu verhindern. 
            </p>
              <p>
              Dennoch nimmt die Kundschaft zur Kenntnis und erklärt sich damit einverstanden, dass die Anlageberaterin
              oder mit ihr verbundene Unternehmen sowie ihre Angestellten und von ihr beigezogene Dritte Tätigkeiten
              ausüben können, welche direkt oder indirekt mit den im Kontext der Anlageberatung verwendeten
              Finanzinstrumenten zusammenhängen. Beispielsweise können sie diese Finanzinstrumente gleichzeitig im eigenen Namen oder im Namen anderer Kunden kaufen oder
              verkaufen oder entgegengesetzte Geschäfte tätigen.
              Die Kundschaft nimmt ferner zur Kenntnis, dass sich die Anlageberatung auf Finanzinstrumente beziehen
              kann, welche von der Anlageberaterin oder von mit ihr verbundenen
              Unternehmen ausgegeben, beraten, entwickelt</p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="5" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p> und/oder kontrolliert werden und dass Angestellte dieser
              Unternehmen im Verwaltungsrat der Emittenten solcher Finanzinstrumente oder im Verwaltungsrat von damit
              verbundenen Unternehmen sein können.</p>
            </td>
          </tr>
          <!--<tr>
            <td class="padding-top"></td>
            <td class="padding-left padding-top">
              sie diese Finanzinstrumente gleichzeitig im eigenen Namen oder im Namen anderer Kunden kaufen oder
              verkaufen oder entgegengesetzte Geschäfte tätigen.
              Die Kundschaft nimmt ferner zur Kenntnis, dass sich die Anlageberatung auf Finanzinstrumente beziehen
              kann, welche von der Anlageberaterin oder von mit ihr verbundenen
              Unternehmen ausgegeben, beraten, entwickelt und/oder kontrolliert werden und dass Angestellte dieser
              Unternehmen im Verwaltungsrat der Emittenten solcher Finanzinstrumente oder im Verwaltungsrat von damit
              verbundenen Unternehmen sein können.
            </td>
          </tr>-->

          <tr>
            <td class="text-bold padding-top" style="">4.</td>
            <td class="text-bold padding-left padding-top">Beratungsdienstleistungen gegenüber Bevollmächtigten</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>Hat die Kundschaft Drittpersonen eine Vollmacht über das Beratungsportfolio erteilt, können diese die
              Anlageberatung im Namen der Kundschaft entgegennehmen. Die Anlageberaterin ist berechtigt, aber nicht
              verpflichtet, die Anlageberatung dem Bevollmächtigten gegenüber zu kommunizieren.</p>
            </td>
          </tr>

          <tr>
            <td class="text-bold padding-top" style="">5.</td>
            <td class="text-bold padding-left padding-top">Entschädigungen und Kosten</td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">5.1.</td>
            <td class="text-bold padding-left padding-top">Beratungsgebühr</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                <span v-if="this.contractData.pricingModel == 'Hourly'">
                  Die Anlageberaterin ist berechtigt, eine Beratungsgebühr in der Höhe von total CHF {{
                    this.contractData.rate
                  }}.-- pro Stunden zu erheben.
                </span>
                  <span v-if="this.contractData.pricingModel == 'Fixed'">
                      Die Anlageberaterin ist berechtigt, eine Beratungsgebühr in der Höhe von total CHF {{
                    this.contractData.rate
                  }}.-- zu erheben.
                </span>
              </p>
              <p>
                Eine allfällige Mehrwertsteuer wird der Kundschaft zusätzlich in Rechnung gestellt. Die Beratungsgebühr
                ist jeweils innerhalb 30 Tagen nach Rechnungsausstellung fällig.
              </p>
              <p>
                Zudem hat die Kundschaft der Anlageberaterin sämtlich im Zusammenhang mit der Anlageberatung stehenden
                Auslagen zu ersetzen.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">5.2.</td>
            <td class="text-bold padding-left padding-top">Entschädigungen durch Dritte</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>Die Anlageberaterin erhält keine Entschädigung von Dritten.</p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">5.3.</td>
            <td class="text-bold padding-left padding-top">Kosten, Honorar und Gebühren Dritter</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Neben der Vergütung der Anlageberaterin können für die Kundschaft weitere Gebühren und Kosten entstehen,
                die von der Depotbank für die Aufbewahrung oder den Erwerb oder die Veräusserung von Finanzinstrumenten
                erhoben werden.
                Ferner können andere Gebühren und Steuern von den Märkten, an denen die Transaktionen durchgeführt werden,
                sowie Stempelsteuer und andere ausländische Steuern und Abgaben erhoben werden. Diese Gebühren, Kosten und Steuern sind von der Kundschaft zu bezahlen. Informationsanfragen hinsichtlich dieser
                Kosten sind an die Depotbank zu richten.
                </p>
                <p>
                Die Kundschaft haftet für sämtliche Steuerverbindlichkeiten, welche sich aus ihrem Portfolio ergeben. Dazu
                gehören insbesondere sämtliche Steuern und Abgaben, welche aus Kauf- und Verkaufstransaktionen
                resultieren, einschliesslich der Steuerverbindlichkeiten bei der Rücknahme oder Liquidation von
                Anlageinstrumenten oder der Kündigung dieses Vertrages.
                </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">6.</td>
            <td class="text-bold padding-left padding-top">Sorgfalt und Haftung</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Anlageberaterin erbringt ihre Dienstleistungen unter diesem Vertrag mit der geschäftsüblichen
                Sorgfalt.
              </p>
                <p>
                Die Anlageberaterin haftet nur für grob fahrlässiges und vorsätzliches Handeln und den daraus entstehenden
                direkten Schaden. Jede weitere Haftung, insbesondere für indirekten Schaden sowie Folge- und Sonderschäden
                ist in jedem Fall ausgeschlossen.
                </p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="6" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Kundschaft anerkennt, dass die jeweilige Anlageberatung aufgrund der Marktabhängigkeit eine zeitlich
                begrenzte Gültigkeit hat und daher von der Kundschaft umgehend umgesetzt werden sollte. Die
                Anlageberaterin haftet nicht für Schäden, die entstehen, wenn die Kundschaft nicht erreichbar ist, oder
                wenn die Übermittlung von Anlageempfehlungen an die Kundschaft unvollständig erfolgte. Eine Haftung der
                Anlageberaterin ist ferner ausgeschlossen, wenn sich die Kundschaft im Ausland aufhält und die
                Leistungserbringung durch die Bank aufgrund ausländischer rechtlicher oder regulatorischer Vorgaben nicht
                zulässig ist. Die Anlageberaterin gewährt keine dauernde Erreichbarkeit und haftet nicht, falls die
                Kundschaft sie nicht rechtzeitig erreichen kann.
                </p>
            </td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Die Anlageberaterin ist nicht für die Einhaltung der eigenen Regeln von Finanzinstrumenten, wie
                Anlagestrategie und Anlagerichtlinien (z.B. die Einhaltung der eigenen Anlagerichtlinien durch einen
                Fonds) verantwortlich. Mit Bezug auf Finanzinstrumente von Drittanbietern haftet die Anlageberaterin weder
                für unrichtige oder unterlassene Angaben in Prospekten oder anderen zur Verfügung gestellten Materialien
                noch für jegliche Art von Verlusten, welche auf solche unrichtigen oder unterlassenen Angaben
                zurückzuführen sind.
              </p>
            </td>
          </tr>

          <tr>
            <td class="text-bold padding-top" style="">7.</td>
            <td class="text-bold padding-left padding-top">Inkrafttreten / Dauer / Beendigung</td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">7.1.</td>
            <td class="text-bold padding-left padding-top">Inkrafttreten</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Dieser Vertrag tritt mit Unterzeichnung durch die Parteien in Kraft. Die Anhänge 1 bis 3 bilden einen
                integrierenden Bestandteil des Vertrags.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">7.2.</td>
            <td class="text-bold padding-left padding-top">Jederzeitiges Kündigungsrecht</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Dieser Vertrag kann von jeder Partei jederzeit schriftlich gekündigt werden. Die Kündigung wird wirksam,
                sobald eine entsprechende schriftliche Mitteilung zugegangen ist. 
              </p>
              <p>
                Die Anlageberaterin haftet nicht für allfälligen Schaden, welcher der Kundschaft nach der gültigen
                Kündigung entsteht. 
              </p>
              <p>
                Im Falle der Kündigung ist die Entschädigung pro rata temporis zu entrichten.
                </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">7.3.</td>
            <td class="text-bold padding-left padding-top">Konkurs, Tod, Verschollenerklärung oder
              Handlungsunfähigkeit
            </td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left ">
              <p>
                Der Vertrag bleibt beim Verlust der Handlungsfähigkeit, beim Konkurs oder Tod der Kundschaft sowie bei
                einer Verschollenerklärung in Kraft. Die Anlageberaterin ist jedoch berechtigt, die Ausführung dieses
                Vertrags oder alle schriftlichen oder mündlichen Weisungen zu suspendieren oder zu verweigern, sofern sie
                vom Tod oder der Handlungsunfähigkeit der Kundschaft Kenntnis erlangt.
              </p>
                <p> 
                Die Anlageberaterin ist berechtigt, den gesetzlichen oder testamentarischen Erben der verstorbenen
                Kundschaft bzw. dem gesetzlichen Vertreter der handlungsunfähig gewordenen Kundschaft Informationen über
                diesen Vertrag zu erteilen.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">8.</td>
            <td class="text-bold padding-left padding-top">Weitere Bestimmungen</td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">8.1.</td>
            <td class="text-bold padding-left padding-top">Vertragsänderungen</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Änderungen des Vertrags oder der Anhänge 1 bis 3 bedürfen zu ihrer Gültigkeit der Schriftform. <span
                  class="text-bold">Anhang 2</span> (Informationen über die Anlageberaterin und das Anlageuniversum) kann
                von der Anlageberaterin einseitig geändert werden.
                </p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="7" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <table>
          <tr>
            <td class="text-bold padding-top" style="">8.2.</td>
            <td class="text-bold padding-left padding-top">Mitteilungen / Kommunikation</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Vorbehaltlich einer anderen Vereinbarung in diesem Vertrag müssen alle Mitteilungen und andere
                Kommunikation, die im Zusammenhang mit diesem Vertrag gemacht werden, in durch Text nachweisbarer Form per
                E-Mail oder Briefpost, an die auf Seite 1 genannten Adressen erfolgen.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">8.3.</td>
            <td class="text-bold padding-left padding-top">Geheimhaltung</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Sämtliche zwischen den Parteien im Rahmen dieses Vertrags zugestellten Informationen und Unterlagen,
                unabhängig von deren Form, sind streng vertraulich zu behandeln. Die Kundschaft hat insbesondere nicht das
                Recht, die von der Anlageberaterin zugestellten Informationen, Dokumente und Daten etc.
                ohne vorgängige schriftliche Zustimmung der Anlageberaterin an Dritte herauszugeben. Diese Verpflichtung
                gilt ohne zeitliche Begrenzung über die Beendigung oder den Ablauf des Vertrags hinaus. Von der
                Geheimhaltungspflicht ausgenommen sind Informationen, die beim Vertragsabschluss öffentlich bekannt waren.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">8.4.</td>
            <td class="text-bold padding-left padding-top">Salvatorische Klausel</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Sollte dieser Vertrag ganz oder teilweise rechtsunwirksam sein oder werden, so bleiben die übrigen
                Bestimmungen davon unberührt. Die Parteien verpflichten sich, eine unwirksame Bestimmung durch eine solche
                wirksame Bestimmung zu ersetzen, die wirtschaftlich dem Zweck der unwirksamen Bestimmung möglichst
                nahekommt. Entsprechendes gilt im Falle einer Regelungslücke.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-bold padding-top" style="">8.5.</td>
            <td class="text-bold padding-left padding-top">Anwendbares Recht und Gerichtsstand</td>
          </tr>
          <tr>
            <td class="padding-top"></td>
            <td class="padding-left">
              <p>
                Der vorliegende Vertrag untersteht materiellem schweizerischen Recht, insbesondere dem Auftragsrecht,
                unter Ausschluss des Kollisionsrechts. Sofern unter zwingendem Recht zulässig, verzichtet die Kundschaft
                ausdrücklich auf die Anwendung des Rechts seines Sitz- oder Wohnsitzstaates, das für ihn günstiger sein
                kann.
                </p>
                <p>
                  Aufsichtsrechtliche Pflichten der Anlageberaterin werden nur zum Vertragsinhalt, sofern dies zwischen
                  den Parteien schriftlich vereinbart wurde. 
                </p>
                <p >
                  Alle sich aus oder im Zusammenhang mit diesem Vertrag ergebenden Streitigkeiten, einschliesslich solcher
                  über das gültige Zustandekommen, die Rechtswirkung, Abänderung oder Auflösung, werden durch die
                  ordentlichen Gerichte in Baar ZG beurteilt. Vorbehalten bleiben zwingende gesetzliche Zuständigkeiten.
                </p>
                <p>
                  Für Streitigkeiten aus diesem Vertrag hat die Kundschaft die Möglichkeit, bei der Finanzombudsstelle
                  Schweiz (FINOS), Talstrasse 20, 8001 Zürich, welcher die Anlageberaterin angeschlossen ist ein
                  Vermittlungsverfahren einzuleiten.
                  </p>
            </td>
          </tr>
        </table>
        <PageFooter page-count="8" :total="total"/>
      </div>

      <div class="page" style="text-align: left;">
        <page-header/>
        <div class="">
          <p>Ausgefertigt in 2 Exemplaren am eingangs genannten Datum.</p><br/>

          <table class="customer-table" style="width: 100%;">
            <tr>
              <td>
                <p><span class="text-bold">Die Kundschaft:</span></p>
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="errorMessages.signature1" class="error-message">{{ errorMessages.signature1 }}</div>
              </td>
              <td></td>
              <td>
                <div v-if="errorMessages.signature2" class="error-message">{{ errorMessages.signature2 }}</div>
              </td>
              <td></td>

            </tr>
            <tr>
              <td style="padding: 4px;border-bottom: 2px solid #333; width: 49%;">
                <!--                    <vue-signature-pad class="signature-pad" ref="signaturePad1" :options="options"/>-->
                <SignatureComponent v-model="contractData.person1.signature"/>
              </td>
              <td style="padding: 8px;">
                &nbsp;
                <!--                  <i @click="undo1" class="pi pi-refresh clear-icon" style="font-size: 1.5rem"></i>-->
              </td>
              <td v-if="isPerson2" style="padding: 4px;border-bottom: 2px solid #333; width: 49%;">
                <!--                    <vue-signature-pad class="signature-pad" ref="signaturePad2" :options="options"/>-->

                <SignatureComponent v-model="contractData.person2.signature"/>

              </td>
              <td>
                <!--                  <i v-if="isPerson2" @click="undo2" class="pi pi-refresh clear-icon" style="font-size: 1.5rem"></i>-->
              </td>
            </tr>


            <tr>
              <td style="width: 50%;">
                <td>
                  {{this.contractData.person1.contactFirstname}} {{ this.contractData.person1.contactLastName }} 
                </td>
              </td>
              <td></td>
              <td v-if="isPerson2" style="width: 50%;">

                <td>
                  {{ this.contractData.person2.contactFirstname }} {{ this.contractData.person2.contactLastName }} 
                </td>
              </td>
            </tr>
          </table>
        </div>

        <div class="section-padding">
          <table class="customer-table" :style="{ width: isPerson2 ? '49%' : '50%' }">
            <tr>
              <td>
                <p><span class="text-bold">Die Anlageberaterin:</span></p>
              </td>
            </tr>
            <tr>
              <td style="padding: 8px;border-bottom: 2px solid #333;">
                <img src="https://melinascheuber.my.site.com//WebForm/file-asset/signaturepng?v=1" alt="signature" width="110px;" height="55px;">
              </td>
            </tr>
            <tr>
              <td>
                <p>Melina Scheuber</p>
                <p>Geschäftsführerin Melina Scheuber AG</p>
              </td>
            </tr>
          </table>
        </div>

        <p style="padding-top:0.5in;"><span class="text-bold">Anhänge:</span></p>
        <table class="attachments-table">
          <tr>
            <td class="padding-top">Anhang 1:</td>
            <td class="padding-top">Risikoprofil der Kundschaft und Kundensegmentierung</td>
          </tr>
          <tr>
            <td class="padding-top">Anhang 2:</td>
            <td class="padding-top">
              <a href="https://static1.squarespace.com/static/63948b67c1431c1fac0d9c89/t/6654dd7c06ec2b47ad64fe46/1716837756095/Anhang+2+Informationen+Anlageberaterin+und+Anlageuniversum.pdf"
                 target="_blank">
                Informationen über die Anlageberaterin und das Anlageuniversum
              </a>
            </td>
          </tr>
          <tr>
            <td class="padding-top">Anhang 3:</td>
            <td class="padding-top">
              Broschüre "<a href="https://www.swissbanking.ch/_Resources/Persistent/8/c/6/e/8c6eaad9339b1ce68b7098f806f46ffa85e6618c/SBVg_Risiken_im_Handel_mit_Finanzinstrumenten_2023_DE.pdf"
                target="_blank">Risiken im Handel mit Finanzinstrumenten</a>"
            </td>
          </tr>
        </table>
        <PageFooter page-count="9" :total="total"/>
      </div>
      </div>
      <br/>
      <br/>

      <div class="button-container-space">
      </div>
      <div class="button-container">
        <!--<button v-on:click="submitForm">Submit</button>-->
        <button v-on:click="submitForm">Absenden</button>
      </div>

    </div>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import PageFooter from '../components/PageFooter.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import 'primeicons/primeicons.css'
import axios from 'axios';
import {useRouter} from 'vue-router';
import SignatureComponent from '../components/AFSignatureComponent.vue'


export default {
  name: 'ContractView',
  components: {PageFooter, PageHeader, SignatureComponent},

  data() {
    return {
      contractData: {},
      isPerson2: false,
      total: 9,
      loading: true,
      showSuccessMessage: false,
      showErrorMessage: false,
      showContent: false,
      errorMessage: '',
      dropDownOptions: ['ja', 'nein'],
      errorMessages: {},

    };
  },

  created() {

    const recordId = this.$route.query.id;
    console.log('recordId -> : ' + recordId);


    if (recordId) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const endpointUrl = `${baseUrl}/contract?id=${recordId}`;
      //let endpointUrl = `https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/contract?id=` + recordId;

      axios.get(endpointUrl)
          .then(response => {
            console.log(response.status);
            if (response.status == 200) {
              this.showErrorMessage = false;
              // JSON responses are automatically parsed.
              setTimeout(() => {
                this.loading = false;
              }, 2000);
              console.log('contractData');

              // Parse the outer JSON
              this.contractData = response.data.data;
              console.log(this.contractData);
              this.showContent = true;
              this.isPerson2 = this.contractData.contractType != 'One Person' ? true : false;
              console.log('isPerson2 :' + this.isPerson2);
            } else {
              this.showContent = false;
            }
          })
          .catch(response => {
            console.log(response);
            this.showContent = false;
            this.showErrorMessage = true;
            this.loading = false;
            this.errorMessage = 'The URL you provided is invalid or has expired.';
          });
    } else {
      this.showErrorMessage = true;
      this.showContent = false;
      this.loading = false; // Reset loading state after unsuccessful submission
      this.errorMessage = 'The URL you provided is invalid or has expired.';
    }
  },

  methods: {
    submitForm() {

      if (this.validateForm()) {


        /*this.saveSignature1();
        this.saveSignature2();*/

        this.contractData.tableContent = [];

        console.log('contractData');
        console.log(this.contractData);

        const recordId = this.$route.query.id;
        console.log('recordId -> : ' + recordId);

        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const endpointUrl = `${baseUrl}/contract?id=${recordId}`;

        //let endpointUrl = `https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/contract?id=` + recordId;

        if (recordId) {
          this.loading = true;
          this.showContent = false;
          axios.post(endpointUrl, this.contractData)
              .then(response => {
                if (response.status == 200) {
                  this.showSuccessMessage = true;
                  this.showErrorMessage = false;
                  this.loading = false; // Reset loading state after successful submission
                  console.log('response');
                  console.log(response.data.data);
                  this.$router.push({path: '/success-page', query: {id: recordId}});
                }
              })
              .catch(error => {
                this.showErrorMessage = true;
                this.showSuccessMessage = false;
                this.showContent = false;
                this.loading = false; // Reset loading state after unsuccessful submission
                this.errorMessage = 'An error occurred while submitting the form.';
                console.error("There was an error!", error);
              });
        } else {

        }
      }
    },

    clearErrorMessage(field) {
      if (this.errorMessages[field]) {
        this.$delete(this.errorMessages, field);
      }
    },


    validateForm() {
      this.errorMessages = {};
      let isValid = true;

      // Validate signature fields
      if (!this.contractData.person1.signature) {
        this.errorMessages.signature1 = 'hier ist eine Unterschrift erforderlich';
        isValid = false;
      }
      if (this.isPerson2 && !this.contractData.person2.signature) {
        this.errorMessages.signature2 = 'hier ist eine Unterschrift erforderlich';
        isValid = false;
      }

      // Validate dropdown fields
      if (!this.contractData.advisorInitiativeInfoRequested) {
        //this.errorMessages.advisorInitiativeInfoRequested = 'Please select an option';
        this.errorMessages.advisorInitiativeInfoRequested = 'Bitte wählen Sie eine Option';
        isValid = false;
      }
      if (!this.contractData.advisorInfoRequested) {
        //this.errorMessages.advisorInfoRequested = 'Please select an option';
        this.errorMessages.advisorInfoRequested = 'Bitte wählen Sie eine Option';
        isValid = false;
      }

      // If form is not valid, scroll to the first error
      if (!isValid) {
        this.$nextTick(() => {
          const firstError = document.querySelector('.error-message');
          if (firstError) {
            firstError.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        });
      }

      return isValid;
    },


    /*    saveSignature1() {
          const {isEmpty, data} = this.$refs.signaturePad1.saveSignature();
          console.log('isEmptySign1 : ' + isEmpty);
          console.log('signature Person1:');
          console.log(data);
          this.contractData.person1.signature = data;
        },
        saveSignature2() {
          const {isEmpty, data} = this.$refs.signaturePad2.saveSignature();
          console.log('isEmptySign2 : ' + isEmpty);
          console.log('signature Person2:');
          console.log(data);
          this.contractData.person2.signature = data;
        },

        undo1() {
          console.log('undo1');
          this.$refs.signaturePad1.undoSignature();
        },
        undo2() {
          console.log('undo2');
          this.$refs.signaturePad2.undoSignature();
        }*/
  }
}
</script>

<style scoped>

#app, #app * {
  box-sizing: content-box;
}

.horizontal_dotted_line td {
  padding: 5px;
}

p {
    text-align: justify;
    margin-bottom:6pt;
    margin-top:3pt;
}


select {
  background-color: #ffffd5; /* Light yellow background */
  border: none; /* Remove default border */
  border-bottom: 2px solid #333; /* Add bottom border */
  padding-top: 2px; /* Add some padding */
  padding-bottom: 2px; /* Add some padding */
  padding-right: 2px; /* Add some padding */
  outline: none; /* Remove outline on focus */
  border-radius: 0px;
  width: 25%;
  margin: 5px 0px;
}

select:focus {
  background-color: #ffffe0; /* Slightly darker yellow on focus */
}

.customer-table {
  width: 100%;
  border-collapse: collapse;
}

.customer-table td {
  min-height: 25px;
}

.signature-pad {
  border: 1px solid #ccc;
  background-color: #fff;
  /*width: 350px; height: 90px;*/
}

.signature-line {
  border-bottom: 1px solid gray;
  min-height: 25px;
  width: 20%;
}

.company-info {
  text-align: center;
}

.attachments-table {
  width: 100%;
}

.section-padding {
  padding-top: 0.2in;
}

.section {
  margin-bottom: 20px;
}

.text-bold {
  font-weight: bold;
}

.padding-left {
  padding-left: 10px;
}

.padding-top {
  padding-top: 5px;
}



.page-container {
  width:  100%;
  max-width: 27cm;
  overflow-x: visible;
  margin: auto;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding-top: 2cm;
  padding-bottom: 2cm;
  padding-right: 3cm;
  padding-left: 2.5cm;
  margin: 1cm auto;
  border: 1px solid #d3d3d3;
  border-radius: 0px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  page-break-after: always;
  position: relative;
  font-family: Arial, sans-serif;
  font-size:11.5pt;
}



@media screen and (max-width: 27cm) {
  .page-container {
    margin-left: 0;
    margin-right: 0;
  }

  .page {
    margin-left: 0;
    margin-right: 0;
    border: none;
    box-shadow: none;
  }
}



.page-header {
  text-align: center;
  padding: 0.9in 0in 0in 0in;
  /*background: #202b91;
  color: #ffffff;*/
  margin: -2cm;
  margin-bottom: 1cm;
}

.page-footer {
  /*display: flex;*/
  justify-content: space-between;
  text-align: right;
  font-size: 14px;
  color: #333;
  position: absolute;
  bottom: 0px;
  left: 0;
  padding-left: 8.9in;
  padding-bottom: 0.3in;
  font-size:9pt;
  font-family: Arial, sans-serif;
}

.page-footer span {
  padding: 32px;
}

.page-footer span:first-child {
  margin-right: auto;
}

.page-footer span:last-child {
  margin-left: 7.9in;
}

body {
  font-family: Avenir Next LT Pro, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  font-weight: 100;
}

.heading-font-style {
  font-weight: bold;
  font-size: 30px;
  text-align: left;
  color: #000000
}

.button-container-space {
  width: 100%;

}

.button-container {
  background-color: #fdfbf6;
  z-index: 550;
  padding: 20px;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2)
}

button {
  background-color: #BF6849;
  border: none;
  color: white;

  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 48px;

}

button:hover {
  background-color: #8e4e37;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signature-box img {
  max-width: 100%;
  max-height: 100%;
}

.error-message {
  position: absolute;
  background-color: rgb(190, 104, 73);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8em;
  z-index: 500;
  white-space: nowrap;
  pointer-events: none; /* Allows clicking through the error message */
}


select + .error-message {
  right: 0px;
  top: 34px;
}

/* Ensure input containers have relative positioning */
.signature-pad,
select {
  position: relative;
}

/* Add some bottom margin to inputs to accommodate error messages */
.signature-pad,
select {
  margin-bottom: 20px;
}

/* Optional: Add a subtle animation for error messages */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.error-message {
  animation: fadeIn 0.3s ease-in-out;
}

</style>  