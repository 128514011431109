
<template>
    <div style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
        <div>
        <i class="pi pi-check-circle" style="font-size: 3rem;color: #BF6849;"></i>
        </div>
        <div>
        <!--<p>Form submitted successfully!</p>-->
        <p>Formular erfolgreich abgeschickt!</p>
        </div>
        <router-link style="background-color: #BF6849; color:aliceblue"
            :to="{ path: '/', query: { id: this.contractId } }"
            class="btn btn-light ms-success"
        >
          <!--Go to home-->
          zurück zum Menü
        </router-link>
    </div>
</template> 
<script>

export default {
    data() {
      return {
        contractId : {},
      };
    },

    created() {
      this.contractId = this.$route.query.id;
      console.log('contractId -> : ' + this.contractId);
    }
}
</script>

<style scoped>
.ms-success{
  border-radius: 0px;
  border: none;
}
</style>