import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import QuizView from '../views/QuizView.vue'
import ContractView from '../views/ContractView.vue'
import RiskProfileView from '@/views/RiskProfileView.vue'
import SuccessPage from '@/views/SuccessPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },

  {
    path: '/quiz',
    name: 'quiz',
    component: QuizView
  },

  {
    path: '/sign-contract',
    name: 'sign-contract',
    component: ContractView
  },

  {
    path: '/sign-report',
    name: 'sign-report',
    component: RiskProfileView
  },

  {
    path: '/success-page',
    name: 'succes-page',
    component: SuccessPage
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
