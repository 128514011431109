<template>
  <div id="app">
    <div v-if="loading" class="loading-container">
      <div class="loading-spinner"></div>
    </div>

    <div v-if="showErrorMessage" style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
      <div>
        <i class="pi pi-exclamation-circle" style="font-size: 3rem;color: #CB0015;"></i>
      </div>
      <div>
        {{ errorMessage }}
      </div>
    </div>

    <div v-else-if="showSuccessMessage" style="text-align: center;font-size: 2rem;margin-top: 3.8in;">
      <div>
        <i class="pi pi-check-circle" style="font-size: 3rem;color: green;"></i>
      </div>
      <div>
        <p>Form submitted successfully!</p>
      </div>
    </div>

    <div v-if="showContent && !loading">

      <div class="page">
        <page-header/>
        <p class="heading-font-style section-padding">Risikoprofil der Kundschaft und Kundensegmentierung - Anhang1</p>
        <br/>
        <table class="fontStyle section-padding" style="width:100%;">
          <tr v-if="!isPerson2">
            <td v-if="riskProfileWrapper.person1" style="text-align: left;">
              Kundschaft: {{ this.riskProfileWrapper.person1.firstname }} {{ this.riskProfileWrapper.person1.lastname }}
              <br/>
              Adresse: {{ this.riskProfileWrapper.person1.address }}
            </td>
            <td v-if="riskProfileWrapper.person2" style="text-align: left;">
              Kundschaft: {{ this.riskProfileWrapper.person2.firstname }} {{ this.riskProfileWrapper.person2.lastname }}
              <br/>
              Adresse: {{ this.riskProfileWrapper.person2.address }}
            </td>
          </tr>
          <tr v-if="isPerson2">
            <td style="text-align: left;">
              Kundschaft: {{ this.riskProfileWrapper.person1.firstname }} {{ this.riskProfileWrapper.person1.lastname }}
              <span v-if="(riskProfileWrapper.person1.firstname || riskProfileWrapper.person1.lastname) && (riskProfileWrapper.person2.firstname || riskProfileWrapper.person2.lastname)">
                  &
                </span>
              {{ this.riskProfileWrapper.person2.firstname }} {{ this.riskProfileWrapper.person2.lastname }}
              <br/>
              Adresse: {{ this.riskProfileWrapper.person1.address }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left;">
              <!--{{ this.riskProfileWrapper.person1.street }}, {{ this.riskProfileWrapper.person1.city }},
             {{ this.riskProfileWrapper.person1.zip }}, {{ this.riskProfileWrapper.person1.state }}, {{ this.riskProfileWrapper.person1.pCountry }}-->
            </td>
          </tr>
        </table>

        <p class="fontStyle section-padding" style="text-align: left;margin-bottom: 0pt;">Anlageberaterin (nachfolgend Firma genannt):
          Melina
          Scheuber AG </p>
        <br/>
        <table class="fontStyle" style="width:100%;">
          <tr>
            <td style="text-align: left;">
              <p>
                Der optimale Weg zum persönlichen Anlageziel
              </p>
              <p style="margin-bottom: 4pt;">
                Sie möchten Ihre Anlagen und Investitionen ganz gezielt auf Ihre Bedürfnisse ausrichten. Um Sie
                bedürfnisgerecht zu beraten, nehmen wir Rücksicht auf Ihre Vermögenssituation, Ihre Risikofähigkeit und
                Ihre
                Risikobereitschaft. Dies setzt voraus, dass Sie Ihre Vermögenssituation sowie Ihre finanziellen
                Kenntnisse
                und Erfahrungen mit uns teilen, und Sie mögliche Änderungen Ihrer Vermögenssituation, Ihrer
                Risikofähigkeit
                und Ihrer Risikobereitschaft uns zeitnah mitteilen.
              </p>
              <p>
                Die Auswertung Ihrer Antworten finden Sie auf der Seite 6.
              </p>
            </td>
          </tr>
        </table>

        <PageFooter page-count="1" :total="total"/>
      </div>
      <div class=" page-container">


        <!--<div class="page">
          <page-header/>
          <div style="text-align: left;">

            <div v-for="(question, index) in this.surveyQuestions" :key="index" >
              // Page Break  <div v-if="shouldInsertPageBreak(question.order)" class="page">


                <div v-if="question.type === 'MultipleChoice'" class="section-padding">
                  <p style="font-weight: bold;">{{ question.tagline }} : {{ question.title }}</p>
                  <div v-for="(option, idx) in question.options" :key="idx">
                    <table class="fontStyle" style="width:100%;">
                      <tr>
                        <td :class="{'selected-value-style': option.selected}"  style="width: 3%; vertical-align: top;">
                          <label>{{ option.value }}</label>
                        </td>

                        <td :class="{'selected-value-style': option.selected}" style="width: 90%; vertical-align: top;">
                          <label>{{ option.label }}</label>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div v-else-if="question.type === 'OpinionScale'">
                  <div v-if="question.subtitle == 'A'">
                    <table class="fontStyle" style="width:100%;">
                      <tr>
                        <td style="width: 25%; vertical-align: top; font-weight: bold;">Ziel</td>
                        <td style="width: 25%; vertical-align: top; font-weight: bold;">Unwichtiges Ziel</td>
                        <td style="width: 25%; vertical-align: top; font-weight: bold; text-align: right;">Wichtiges Ziel</td>
                      </tr>
                    </table>
                  </div>
                  <table style="width:100%;">
                    <thead>
                    <tr>
                      <th style="width: 5%; vertical-align: top;"></th>
                      <th style="width: 15%; vertical-align: top;"></th>
                      <th style="width: 10%; vertical-align: top; text-align: center;" v-for="(option, idx) in question.options" :key="idx">
                        <label v-if="question.subtitle == 'A'">{{ option.label }}</label>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td style="width: 5%; vertical-align: top;font-weight: bold;">{{ question.subtitle }}</td>
                      <td style="width: 5%; vertical-align: top;">{{ question.title }} </td>
                      <td v-for="(option, idx) in question.options" :key="idx" style="vertical-align: top; text-align: center;">
                        <span :class="{'selected-value-style': option.selected}" style="font-size: 15px;">{{ option.selected ? 'SELECTED' : '-' }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div v-else class="section-padding">
                  <div v-if="question.tagline">
                    <p style="font-weight: bold;">{{ question.tagline }} : {{ question.content }}</p>
                  </div>
                  <div v-else>
                    <p class="heading-font-style">{{ question.title }}</p>
                    <p>{{ question.content }}</p>
                  </div>
                </div>
            </div>
          </div>
          <PageFooter page-count="2" :total="total"/>
        </div>-->

        <div v-if="this.surveyQuestions.length > 0" class="page">
          <page-header/>
          <div style="text-align: left;">
            <div v-for="(question, index) in firstRange" :key="index">
              <!-- Render options if it's a MultipleChoice type -->
              <div v-if="question.type === 'MultipleChoice'">
                <br/>
                <p style="font-weight: bold;margin-bottom: 2pt;margin-top: 0pt;">{{ question.tagline }}: {{ question.title }}</p>
                <div v-for="(option, idx) in question.options" :key="idx">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td :class="{'selected-value-style': option.selected}" style="width: 3%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.value }}</p>
                      </td>
                      <!--<td style="width: 5%; vertical-align: top;">
                        <input type="checkbox" :checked="option.selected" disabled/>
                      </td>-->
                      <td :class="{'selected-value-style': option.selected}" style="width: 90%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.label }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- Render options if it's a OpinionScale type -->
              <div v-else-if="question.type === 'OpinionScale'">
                <div v-if="question.subtitle == 'A'">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Unwichtiges Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold; text-align: right;">Wichtiges
                        Ziel
                      </td>
                    </tr>
                  </table>
                </div>
                <table style="width:100%;">
                  <thead>
                  <tr>
                    <th style="width: 5%; vertical-align: top;"></th>
                    <th style="width: 15%; vertical-align: top;"></th>
                    <th style="width: 10%; vertical-align: top; text-align: center;"
                        v-for="(option, idx) in question.options" :key="idx">
                      <label v-if="question.subtitle == 'A'">{{ option.label }}</label>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 5%; vertical-align: top;font-weight: bold;">{{ question.subtitle }}</td>
                    <td style="width: 5%; vertical-align: top;">{{ question.title }}</td>
                    <td v-for="(option, idx) in question.options" :key="idx"
                        style="vertical-align: top; text-align: center;">
                      <!--<input type="checkbox" :checked="option.selected" disabled/>-->
                      <span :class="{'selected-value-style2': option.selected}"
                            style="font-size: 15px;">{{ option.selected ? 'ausgewählt' : '-' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- Display content for other types -->
              <div v-else> 
                <div v-if="question.tagline">
                  <p style="font-weight: bold;">{{ question.tagline }}: {{ question.content }}</p>
                </div>
                <div v-else>
                  <p class="heading-font-style">{{ question.title }}</p>
                  <div v-if="question.content">
                    <br/>
                    <p style="margin-top: 0pt;">{{ question.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PageFooter page-count="2" :total="total"/>
        </div>

        <div v-if="this.surveyQuestions.length > 5" class="page">
          <page-header/>
          <div style="text-align: left;">
            <div v-for="(question, index) in secondRange" :key="index">
              <!-- Render options if it's a MultipleChoice type -->
              <div v-if="question.type === 'MultipleChoice'" class="section-padding">
                <br/>
                <p style="font-weight: bold;margin-bottom: 2pt;margin-top: 0pt;">{{ question.tagline }}: {{ question.title }}</p>
                <div v-for="(option, idx) in question.options" :key="idx">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td :class="{'selected-value-style': option.selected}" style="width: 3%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.value }}</p>
                      </td>
                      <!--<td style="width: 5%; vertical-align: top;">
                        <input type="checkbox" :checked="option.selected" disabled/>
                      </td>-->
                      <td :class="{'selected-value-style': option.selected}" style="width: 90%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.label }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- Render options if it's a OpinionScale type -->
              <div v-else-if="question.type === 'OpinionScale'">
                <div v-if="question.subtitle == 'A'">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Unwichtiges Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold; text-align: right;">Wichtiges
                        Ziel
                      </td>
                    </tr>
                  </table>
                </div>
                <table style="width:100%;">
                  <thead>
                  <tr>
                    <th style="width: 5%; vertical-align: top;"></th>
                    <th style="width: 15%; vertical-align: top;"></th>
                    <th style="width: 10%; vertical-align: top; text-align: center;"
                        v-for="(option, idx) in question.options" :key="idx">
                      <label v-if="question.subtitle == 'A'">{{ option.label }}</label>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 5%; vertical-align: top;font-weight: bold;">{{ question.subtitle }}</td>
                    <td style="width: 5%; vertical-align: top;">{{ question.title }}</td>
                    <td v-for="(option, idx) in question.options" :key="idx"
                        style="vertical-align: top; text-align: center;">
                      <!--<input type="checkbox" :checked="option.selected" disabled/>-->
                      <span :class="{'selected-value-style2': option.selected}"
                            style="font-size: 15px;">{{ option.selected ? 'ausgewählt' : '-' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- Display content for other types -->
              <div v-else class="section-padding">
                <div v-if="question.tagline">
                  <p style="font-weight: bold;">{{ question.tagline }}: {{ question.content }}</p>
                  <br/>
                </div>
                <div v-else>
                  <p class="heading-font-style">{{ question.title }}</p>
                  <br/>
                  <p>{{ question.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <PageFooter page-count="3" :total="total"/>
        </div>

        <div v-if="this.surveyQuestions.length > 15" class="page">
          <page-header/>
          <div style="text-align: left;">
            <div v-for="(question, index) in thirdRange" :key="index">
              <!-- Render options if it's a MultipleChoice type -->
              <div v-if="question.type === 'MultipleChoice'" class="section-padding">
                <p style="font-weight: bold;margin-bottom: 2pt;margin-top: 0pt;">{{ question.tagline }}: {{ question.title }}</p>
                <div v-for="(option, idx) in question.options" :key="idx">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td :class="{'selected-value-style': option.selected}" style="width: 3%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.value }}</p>
                      </td>
                      <!--<td style="width: 5%; vertical-align: top;">
                        <input type="checkbox" :checked="option.selected" disabled/>
                      </td>-->
                      <td :class="{'selected-value-style': option.selected}" style="width: 90%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.label }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- Render options if it's a OpinionScale type -->
              <div v-else-if="question.type === 'OpinionScale'">
                <div v-if="question.subtitle == 'A'">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Unwichtiges Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold; text-align: right;">Wichtiges
                        Ziel
                      </td>
                    </tr>
                  </table>
                </div>
                <table style="width:100%;">
                  <thead>
                  <tr>
                    <th style="width: 5%; vertical-align: top;"></th>
                    <th style="width: 15%; vertical-align: top;"></th>
                    <th style="width: 10%; vertical-align: top; text-align: center;"
                        v-for="(option, idx) in question.options" :key="idx">
                      <label v-if="question.subtitle == 'A'">{{ option.label }}</label>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 5%; vertical-align: top;font-weight: bold;">{{ question.subtitle }}</td>
                    <td style="width: 5%; vertical-align: top;">{{ question.title }}</td>
                    <td v-for="(option, idx) in question.options" :key="idx"
                        style="vertical-align: top; text-align: center;">
                      <!--<input type="checkbox" :checked="option.selected" disabled/>-->
                      <span :class="{'selected-value-style2': option.selected}"
                            style="font-size: 15px;">{{ option.selected ? 'ausgewählt' : '-' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- Display content for other types -->
              <div v-else class="section-padding">
                <div v-if="question.tagline">
                  <p style="font-weight: bold;">{{ question.tagline }}: {{ question.content }}</p>
                </div>
                <div v-else>
                  <p class="heading-font-style">{{ question.title }}</p>
                  <p>{{ question.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <PageFooter page-count="4" :total="total"/>
        </div>

        <div v-if="this.surveyQuestions.length > 19" class="page">
          <page-header/>
          <div style="text-align: left;">
            <div v-for="(question, index) in fourthRange" :key="index">
              <!-- Render options if it's a MultipleChoice type -->
              <div v-if="question.type === 'MultipleChoice'" class="section-padding">
                <p style="font-weight: bold;margin-bottom: 2pt;margin-top: 0pt;">{{ question.tagline }}: {{ question.title }}</p>
                <div v-for="(option, idx) in question.options" :key="idx">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td :class="{'selected-value-style': option.selected}" style="width: 3%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.value }}</p>
                      </td>
                      <!--<td style="width: 5%; vertical-align: top;">
                        <input type="checkbox" :checked="option.selected" disabled/>
                      </td>-->
                      <td :class="{'selected-value-style': option.selected}" style="width: 90%; vertical-align: top;">
                        <p style="margin-bottom:2pt;margin-top:2pt;">{{ option.label }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- Render options if it's a OpinionScale type -->
              <div v-else-if="question.type === 'OpinionScale'">
                <div v-if="question.subtitle == 'A'">
                  <table class="fontStyle" style="width:100%;">
                    <tr>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold;">Unwichtiges Ziel</td>
                      <td style="width: 25%; vertical-align: top; font-weight: bold; text-align: right;">Wichtiges
                        Ziel
                      </td>
                    </tr>
                  </table>
                </div>
                <table style="width:100%;">
                  <thead>
                  <tr>
                    <th style="width: 5%; vertical-align: top;"></th>
                    <th style="width: 15%; vertical-align: top;"></th>
                    <th style="width: 10%; vertical-align: top; text-align: center;"
                        v-for="(option, idx) in question.options" :key="idx">
                      <label v-if="question.subtitle == 'A'">{{ option.label }}</label>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 5%; vertical-align: top;font-weight: bold;">{{ question.subtitle }}</td>
                    <td style="width: 5%; vertical-align: top;">{{ question.title }}</td>
                    <td v-for="(option, idx) in question.options" :key="idx"
                        style="vertical-align: top; text-align: center;">
                      <!--<input type="checkbox" :checked="option.selected" disabled/>-->
                      <span :class="{'selected-value-style2': option.selected}"
                            style="font-size: 15px;">{{ option.selected ? 'ausgewählt' : '-' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- Display content for other types -->
              <div v-else class="section-padding">
                <div v-if="question.tagline">
                  <p style="font-weight: bold;">{{ question.tagline }}: {{ question.content }}</p>
                </div>
                <div v-else>
                  <p class="heading-font-style">{{ question.title }}</p>
                  <p>{{ question.content }}</p>
                </div>
              </div>
            </div>
          </div>
          <PageFooter page-count="5" :total="total"/>
        </div>

        <div class="page">
          <page-header/>
          <!--<p class="section-padding" style="text-align: left;">
            Bitte umkreisen Sie die Punktzahl entsprechend Ihrer Antwort auf jede Frage und zählen Sie diese Punkte
            zusammen. Die Totalpunktzahl für die ersten zwei Abschnitte ergibt das dazugehörige Profil, das jeweils in
            der
            rechten Tabelle anzukreuzen ist.
          </p>-->

          <div class="fontStyle section-padding">
            <table class="fontStyle section-padding" style="width:100%;">
              <tr>
                <td style="text-align:left;" class="heading-font-style">
                  Auswertung I. Risikofähigkeit (Fragen 1 bis 5)
                </td>
              </tr>
            </table>
          </div>
          <div class="fontStyle section-padding">
            <table class="fontStyle section-padding" style="width:100%;">
              <tr>
                <td style="width: 45%; vertical-align: top; text-align: left;padding-top: 10px;">
                  <table style="width: 100%; text-align: left;">
                    <thead>
                    <tr>
                      <th class="text-align-left">Frage</th>
                      <th class="text-align-right">A</th>
                      <th class="text-align-right">B</th>
                      <th class="text-align-right">C</th>
                      <th class="text-align-right">D</th>
                      <th class="text-align-right">E</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold text-align-left" style="width: 10%;"> 1</td>
                      <td class="text-align-right"> +6</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> 0</td>
                      <td class="text-align-right"> +3</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 2</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 3</td>
                      <td class="text-align-right"> 0</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> -1</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 4</td>
                      <td class="text-align-right"> -9</td>
                      <td class="text-align-right"> -4</td>
                      <td class="text-align-right"> +4</td>
                      <td class="text-align-right"> +9</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 5</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
                <td style="width: 5%;"></td>
                <td style="width: 50%; vertical-align: top; text-align: left;padding-top: 10px;">
                  <table style="width: 100%; text-align: left;">
                    <thead>
                    <tr>
                      <th class="text-align-left">Profil Risikofähigkeit</th>

                      <th colspan="3" class="text-align-center">Punkte</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold text-align-left"> sehr gering - Kontolösung</td>
                      <td class="text-align-right"> -30</td>
                      <td class="text-align-center"> bis</td>
                      <td class="text-align-right"> -25</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> Gering</td>
                      <td class="text-align-right"> -24</td>
                      <td class="text-align-center"> bis</td>
                      <td class="text-align-right"> -20</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> Moderat</td>
                      <td class="text-align-right"> -19</td>
                      <td class="text-align-center"> bis</td>
                      <td class="text-align-right"> -7</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> Mittel</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-center"> bis</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> Überdurchschnittlich</td>
                      <td class="text-align-right"> +7</td>
                      <td class="text-align-center"> bis</td>
                      <td class="text-align-right"> +20</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> Hoch</td>
                      <td class="text-align-right"> +21</td>
                      <td class="text-align-right" style="text-align: center;"> bis</td>
                      <td class="text-align-right"> +30</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="text-align:left;padding-top: 5px;">
                  <spna class="selected-value-style">
                    Total Punkte: {{ this.riskProfileWrapper.evaluation1Points }} ({{ this.riskProfileWrapper.evaluation1Profile }})
                  </spna>
                </td>
              </tr>
            </table>
          </div>

          <div class="fontStyle section-padding">
            <table class="fontStyle section-padding" style="width:100%;">
              <tr>
                <td style="text-align:left;padding-top: 10px;" class="heading-font-style">
                  Auswertung II. Risikobereitschaft (Fragen 6 bis 11)
                </td>
              </tr>
            </table>
          </div>

          <div class="fontStyle section-padding">
            <table class="fontStyle section-padding" style="width:100%;">
              <tr>
                <td style="width: 45%; vertical-align: top; text-align: left;padding-top: 10px;">
                  <table style="width: 100%; text-align: left;">
                    <thead>
                    <tr>
                      <th class="text-align-left">Frage</th>
                      <th class="text-align-right">1</th>
                      <th class="text-align-right">2</th>
                      <th class="text-align-right">3</th>
                      <th class="text-align-right">4</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;width: 10%;">6 A</td>
                      <td class="text-align-right">+1.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">-1.5</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;">6 B</td>
                      <td class="text-align-right">-1.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">+1.5</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;">6 C</td>
                      <td class="text-align-right">+1.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">-1.5</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;">6 D</td>
                      <td class="text-align-right">-1.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">+1.5</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;">6 E</td>
                      <td class="text-align-right">+1.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">-1.5</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left" style="text-align: left;">6 F</td>
                      <td class="text-align-right">-1.5</td>
                      <td class="text-align-right">-0.5</td>
                      <td class="text-align-right">+0.5</td>
                      <td class="text-align-right">+1.5</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
                <td style="width: 5%;"></td>
                <td style="width: 50%; vertical-align: top; text-align: left;padding-top: 10px;">
                  <table style="width: 100%; text-align: left;">
                    <thead>
                    <tr>
                      <th class="text-align-left">Profil Risikofähigkeit</th>
                      <th colspan="3" class="text-align-center">Punkte</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold text-align-left">sehr gering - Kontolösung</td>
                      <td class="text-align-right">-33</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">-26</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left">Gering</td>
                      <td class="text-align-right">-25</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">-19</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left">Moderat</td>
                      <td class="text-align-right">-18</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">-6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left">Mittel</td>
                      <td class="text-align-right">-5</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">+7</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left">Überdurchschnittlich</td>
                      <td class="text-align-right">+8</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">+20</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left">Hoch</td>
                      <td class="text-align-right">+21</td>
                      <td class="text-align-center">bis</td>
                      <td class="text-align-right">+33</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div class="fontStyle section-padding">
            <table style="width:45%;">
              <tr>
                <td style=" vertical-align: top; text-align: left;padding-top: 10px;">
                  <table style="width: 100%; text-align: left;">
                    <thead>
                    <tr>
                      <th class="text-align-left">Frage</th>
                      <th class="text-align-right">A</th>
                      <th class="text-align-right">B</th>
                      <th class="text-align-right">C</th>
                      <th class="text-align-right">D</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold text-align-left" style="width: 10%;"> 7</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> 0</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 8</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 9</td>
                      <td class="text-align-right"> -6</td>
                      <td class="text-align-right"> 0</td>
                      <td class="text-align-right"> +3</td>
                      <td class="text-align-right"> +6</td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 10</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> +3</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="text-bold text-align-left"> 11</td>
                      <td class="text-align-right"> -3</td>
                      <td class="text-align-right"> +3</td>
                      <td></td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="text-align:left;padding-top: 10px;">
                  <spna class="selected-value-style">
                    Total Punkte: {{ this.riskProfileWrapper.evaluation2Points }} ({{ this.riskProfileWrapper.evaluation2Profile }})
                  </spna>
                </td>
              </tr>
            </table>
          </div>

          <PageFooter page-count="6" :total="total"/>
        </div>

        <div class="page">
          <page-header/>
          <p class="heading-font-style section-padding" style="text-align: left;">Details der Risikoprofile </p>
          <table class="fontStyle section-padding" style="width:100%;">
            <thead>
            <tr>
              <th style="width: 10%;" class="text-align-left padding-table">Risikoprofil</th>
              <th style="width: 10%;" class="text-align-center padding-table">Strategische Aktiengewichtun</th>
              <th style="width: 10%;" class="text-align-center padding-table">Strategiebandbreite Aktien (min / max)</th>
            </tr>
            </thead>
            <tbody>
            <!--<tr>
              <td style="text-align: left;">
                <label style="font-weight: bold;">Sehr Gering</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">
                    Sehr Gering
                </p>
                <span style="font-size:8pt">Kontolösung</span>
              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                0%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                0%
              </td>
            </tr>

            <!--<tr>
              <td style="text-align: left;" class="padding-top">
                <label style="font-weight: bold;">Gering</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">Gering</p>

                <span style="font-size:8pt">Die Anlagestrategie bietet regelmässige Erträge
                und zielt auf die reale Werterhaltung des
                Vermögens ab. Es treten nur geringe
                Vermögensschwankungen auf. Die Anlagestrategie
                ist für jede Anlagedauer geeignet.</span>
              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                15%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                5% - 30%
              </td>
            </tr>

            <!--<tr>
              <td style="text-align: left;" class="padding-top">
                <label style="font-weight: bold;">Moderat</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">Moderat</p>

                <span style="font-size:8pt">Die Anlagestrategie bietet regelmässige Erträge
                und ein geringes Vermögenswachstum durch
                Kapitalgewinne. Es treten nur geringe
                Vermögensschwankungen auf und die Risiken sind
                gering. Die Anlagestrategie ist für eine mittlere bis
                lange Anlagedauer geeignet.</span>
              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                30%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                10% bis 35%
              </td>
            </tr>

            <!--<tr>
              <td style="text-align: left;" class="padding-top">
                <label style="font-weight: bold;">Mittel</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">Mittel</p>

                <span style="font-size:8pt">Die Anlagestrategie bietet ein moderates
                Vermögenswachstum durch Kapitalgewinne. Es
                treten mittlere Vermögensschwankungen auf. Die
                Anlagestrategie ist für eine mittlere bis lange
                Anlagedauer geeignet.</span>
              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                50%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                20% bis 65%
              </td>
            </tr>

            <!--<tr>
              <td style="text-align: left;" class="padding-top">
                <label style="font-weight: bold;">Überdurchschnittlich</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">Überdurchschnittlich</p>

                <span style="font-size:8pt">Die Anlagestrategie bietet ein langfristiges
                Vermögenswachstum durch eine stärkere
                Ausrichtung auf Kapitalgewinne. Es treten erhöhte
                Vermögensschwankungen auf. Die Anlagestrategie
                ist für eine lange Anlagedauer ab zehn Jahren
                geeignet.</span>

              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                70%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                40% bis 85%
              </td>
            </tr>

            <!--<tr>
              <td style="text-align: left;" class="padding-top">
                <label style="font-weight: bold;">Hoch</label>
              </td>
            </tr>-->
            <tr>
              <td style="text-align: left; font-size: 14px;width: 30%;padding:8px;" class="text-align-left padding-table">
                <p style="font-weight: bold;margin-top:0pt;margin-bottom:2pt;">Hoch</p>

                <span style="font-size:8pt">Die Anlagestrategie bietet ein langfristiges
                Vermögenswachstum durch eine gezielte
                Fokussierung auf Kapitalgewinne. Es treten hohe
                Vermögensschwankungen auf. Die Anlagestrategie
                ist für eine lange Anlagedauer ab zehn Jahren
                geeignet.</span>
              </td>
              <td style="text-align: center;font-size: 14px;width: 10%;" class="text-align-center">
                85%
              </td>
              <td style="text-align: center;font-size: 14px;width: 20%" class="text-align-center">
                50% bis 95%
              </td>
            </tr>
            </tbody>
          </table>
          <PageFooter page-count="7" :total="total"/>
        </div>

        <div class="page">
          <page-header/>

          <p class="heading-font-style section-padding" style="text-align: left;">Ergebnis - Ihr ermitteltes Risikoprofil </p>
          <br/>
          <table class="fontStyle" style="width:100%;">
            <tr>
              <td style="text-align: left;">
                <p style="margin-top:0pt;">
                Das Risikoprofil ergibt sich aufgrund der Risikobereitschaft und der Risikofähigkeit. Falls die
                Klassifizierung bei I. und II. nicht übereinstimmt, wird die niedrigste Einstufung für die Beurteilung
                genommen.
              </p>
              <p>
                Ich nehme mein ermitteltes Risikoprofil zur Kenntnis, und wünsche eine entsprechende Umsetzung für meine
                Vermögenswerte. Das ermittelte Risikoprofil ist:
              
                <!--q Sehr gering / q Gering / q Moderat / q Mittel / q Überdurchschnittlich / q Hoch-->
                <span class="selected-value-style">
                  {{ this.riskProfileWrapper.evaluationProfileResult }}
                </span>
              </p>
              </td>
            </tr>
          </table>

          <p class="heading-font-style section-padding" style="text-align: left;">Kundensegmentierung:
            Privatkund:in </p>

          <p class="section-padding" style="text-align: left;margin-top:0pt;">
            Ich erkläre hiermit, dass ich mit den Risiken, die üblicherweise mit dem Kauf, dem Verkauf und dem Halten
            von Effekten verbunden sind, vertraut bin. Dazu gehören insbesondere die Bonitäts- und Kursrisiken von Aktien,
            Obligationen und kollektiven Kapitalanlagen, die mit der Anlage in entsprechenden Effekten verbunden sind.
          </p>

          <div class="section-padding">
            <table class="fontStyle" style="width:85%;">
              <tr>
                <td>
                  <div v-if="errorMessages.place1" class="error-message">{{ errorMessages.place1 }}</div>
                </td>
                <td></td>
                <td>
                  <div v-if="errorMessages.signature1" class="error-message">{{ errorMessages.signature1 }}</div>
                </td>
                <td></td>
              </tr>

              <tr v-if="riskProfileWrapper.person1">
                <td style="border-bottom: 2px dotted #333; width: 43%; text-align: left; vertical-align: bottom;">
                  <input type='text' v-model='this.riskProfileWrapper.person1.place' placeholder="Ort ergänzen"/>
                  <span style="padding-left: 20px;;">{{ this.riskProfileWrapper.currentDate }}</span>
                </td>
                <td style="width: 15%; "></td>
                <td style="border-bottom: 2px dotted #333; width: 43%; text-align: left; vertical-align: top;">
                  <SignatureComponent v-model="this.riskProfileWrapper.person1.signature"/>
                </td>
              </tr>

              <tr v-if="riskProfileWrapper.person1">
                <td style="text-align: left; vertical-align: top;">
                  Ort, Datum
                </td>
                <td style="width: 10%; "></td>
                <td style="text-align: left; vertical-align: top;">
                  {{ this.riskProfileWrapper.person1.firstname }} {{ this.riskProfileWrapper.person1.lastname }}
                  <br/><br/>
                  Kundschaft
                </td>
              </tr>

              <tr>
                <td>
                  <div v-if="errorMessages.place2" class="error-message">{{ errorMessages.place2 }}</div>
                </td>
                <td></td>
                <td>
                  <div v-if="errorMessages.signature2" class="error-message">{{ errorMessages.signature2 }}</div>
                </td>
                <td></td>
              </tr>

              <tr v-if="riskProfileWrapper.person2">
                <td style="border-bottom: 2px dotted #333; width: 43%; text-align: left; vertical-align: bottom;">
                  <input type='text' v-model='this.riskProfileWrapper.person2.place' placeholder="Ort ergänzen"/>
                  <span style="padding-left: 20px;;">{{ this.riskProfileWrapper.currentDate }}</span>
                </td>
                <td style="width: 15%; "></td>
                <td style="border-bottom: 2px dotted #333; width: 43%; text-align: left; vertical-align: top;">
                  <SignatureComponent v-model="this.riskProfileWrapper.person2.signature"/>
                </td>
              </tr>

              <tr v-if="riskProfileWrapper.person2">
                <td style="text-align: left; vertical-align: top;">
                  Ort, Datum
                </td>
                <td style="width: 10%; "></td>
                <td style="text-align: left; vertical-align: top;">
                  {{ this.riskProfileWrapper.person2.firstname }} {{ this.riskProfileWrapper.person2.lastname }}
                  <br/><br/>
                  Kundschaft
                </td>
              </tr>

            </table>
          </div>

          <div class="section-padding">
            <table class="fontStyle section-padding" style="width:85%;">
              <tr>
                <td style="border-bottom: 2px dotted #333; width: 30%; text-align: left; vertical-align: bottom;">
                  Baar, {{ this.riskProfileWrapper.currentDate }}
                </td>
                <td style="width: 10%; "></td>
                <td style="border-bottom: 2px dotted #333; width: 30%; text-align: left; vertical-align: top;">
                  <img src="https://melinascheuber.my.site.com//WebForm/file-asset/signaturepng?v=1" alt="signature"
                       width="110px;" height="55px;">
                </td>
              </tr>
              <tr>
                <td style="text-align: left; vertical-align: top;">
                  Ort, Datum
                </td>
                <td style="width: 10%; "></td>
                <td style="text-align: left; vertical-align: top;">
                  Melina Scheuber <br/><br/>
                  Geschäftsführerin Melina Scheuber AG
                </td>
              </tr>
            </table>
          </div>

          <PageFooter page-count="8" :total="total"/>
        </div>
      </div>
      <br/>
      <br/>

      <div class="button-container-space">
      </div>
      <div class="button-container">
        <button v-on:click="submitForm">Absenden</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheckCircle, faExclamationCircle, faSpinner, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import PageHeader from '../components/PageHeader.vue';
import PageFooter from '../components/PageFooter.vue';
import SignatureComponent from '../components/AFSignatureComponent.vue'

library.add(faCheckCircle, faExclamationCircle, faSpinner, faExclamationTriangle);

export default {
  name: 'HomeView',
  components: {
    FontAwesomeIcon, PageHeader, PageFooter, SignatureComponent
  },
  wizardData: {},
  showSuccessMessage: false,
  showErrorMessage: false,
  showContent: false,
  errorMessage: '',
  data() {
    return {
      surveyQuestions: [],
      riskProfileWrapper: {},
      evaluation1Points: null,
      evaluation2Points: null,
      total: 8,
      loading: true,
      showSuccessMessage: false,
      showErrorMessage: false,
      errorMessage: '',
      errorMessages: {},
    };
  },

  computed: {
    firstRange() {
      return this.surveyQuestions.slice(0, 5); // Get questions 1 to 5 (0-indexed)
    },
    secondRange() {
      return this.surveyQuestions.slice(5, 15); // Get questions 6 to 15 (0-indexed)
    },
    thirdRange() {
      return this.surveyQuestions.slice(15, 19); // Get questions 6 to 15 (0-indexed)
    },
    fourthRange() {
      return this.surveyQuestions.slice(19); // Get questions 6 to 15 (0-indexed)
    }
  },

  mounted() {
  },

  created() {
    this.fetchSurveyData();
  },

  methods: {
    fetchSurveyData() {
      const recordId = this.$route.query.id;
      console.log('recordId -> : ' + recordId);

      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const apiUrl = `${baseUrl}/sign-risk-report?id=${recordId}`;

      // Replace with your Salesforce org's URL
      //const apiUrl = 'https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/sign-risk-report?id=' + recordId;

      console.log('Fetching questions from:', apiUrl);

      fetch(apiUrl, {
        method: 'GET',
        headers: {
          // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN_HERE', // Replace with actual authentication
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log('Response status:', response);
            if (!response.ok) {
              this.loading = false;
              this.showContent = false;
              this.showErrorMessage = true;
              this.errorMessage = 'The URL you provided is invalid or has expired.';
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            this.loading = false;
            console.log('Response data:', data);
            if (!data || !data.data || !data.data.quizWrapper.questions) {
              throw new Error('Invalid data structure: Questions array not found.');
            }
            this.showContent = true;
            this.surveyQuestions = data.data.quizWrapper.questions;
            this.riskProfileWrapper = data.data.riskProfileWrapper;
            this.isPerson2 = this.riskProfileWrapper.contractType == 'Family (Two Person) - One Risk Profile' ? true : false;
            console.log('Received questions:', this.surveyQuestions);
            console.log('questions size:', this.surveyQuestions.length);
            console.log('riskProfileWrapper :', this.riskProfileWrapper);
          })
          .catch(error => {
            console.error('Error fetching questions:', error);
            this.loading = false;
            this.showContent = false;
            this.showErrorMessage = true;
            this.errorMessage = 'The URL you provided is invalid or has expired.';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    submitForm() {

      if (this.validateForm()) {

        console.log('riskProfileWrapper');
        console.log(this.riskProfileWrapper);

        const recordId = this.$route.query.id;
        console.log('recordId -> : ' + recordId);

        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const endpointUrl = `${baseUrl}/sign-risk-report?id=${recordId}`;

        //let endpointUrl = `https://melinascheuber--partial.sandbox.my.salesforce-sites.com/Portal/services/apexrest/sign-risk-report?id=` + recordId;

        if (recordId) {
          this.loading = true;
          this.showContent = false;
          axios.post(endpointUrl, this.riskProfileWrapper)
              .then(response => {
                if (response.status == 200) {
                  this.showSuccessMessage = true;
                  this.showErrorMessage = false;
                  this.loading = false; // Reset loading state after successful submission
                  console.log('response');
                  console.log(response.data.data);
                  this.$router.push({path: '/success-page', query: {id: this.riskProfileWrapper.contractId}});
                }
              })
              .catch(error => {
                this.showErrorMessage = true;
                this.showSuccessMessage = false;
                this.showContent = false;
                this.loading = false; // Reset loading state after unsuccessful submission
                this.errorMessage = 'An error occurred while submitting the form.';
                console.error("There was an error!", error);
              });
        } else {

        }
      }
    },

    /*validateForm() {
      this.errorMessages = {};
      let isValid = true;

      // Validate for both person
      if (this.isPerson2) {
        if(!this.riskProfileWrapper.person1.place){
          this.errorMessages.place1 = 'Ort ist erforderlich';
          isValid = false;
        }if(!this.riskProfileWrapper.person2.place){
          this.errorMessages.place2 = 'Ort ist erforderlich';
          isValid = false;
        }if(!this.riskProfileWrapper.person1.signature){
          this.errorMessages.signature1 = 'Unterschrift ist erforderlich';
          isValid = false;
        }if(!this.riskProfileWrapper.person2.signature){
          this.errorMessages.signature2 = 'Unterschrift ist erforderlich';
          isValid = false;
        }
      }else{
        if (this.riskProfileWrapper.person1) {
          if(!this.riskProfileWrapper.person1.place){
            this.errorMessages.place1 = 'Ort ist erforderlich';
            isValid = false;
          }if(!this.riskProfileWrapper.person1.signature){
            this.errorMessages.signature1 = 'Unterschrift ist erforderlich';
            isValid = false;
          }
        }else if(this.riskProfileWrapper.person2){
          if(!this.riskProfileWrapper.person2.place){
            this.errorMessages.place1 = 'Ort ist erforderlich';
            isValid = false;
          }if(!this.riskProfileWrapper.person2.signature){
            this.errorMessages.signature1 = 'Unterschrift ist erforderlich';
            isValid = false;
          }
        }
      }

      // If form is not valid, scroll to the first error
      if (!isValid) {
        this.$nextTick(() => {
          const firstError = document.querySelector('.error-message');
          if (firstError) {
            firstError.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        });
      }

      return isValid;
    },*/

    validateForm() {
      this.errorMessages = {};
      let isValid = true;

      const validatePerson = (person, placeErrorKey, signatureErrorKey) => {
        if (person) {
          if (!person.place) {
            this.errorMessages[placeErrorKey] = 'Ort ist erforderlich';
            isValid = false;
          }
          if (!person.signature) {
            this.errorMessages[signatureErrorKey] = 'Unterschrift ist erforderlich';
            isValid = false;
          }
        }
      };

      if (this.isPerson2) {
        validatePerson(this.riskProfileWrapper.person1, 'place1', 'signature1');
        validatePerson(this.riskProfileWrapper.person2, 'place2', 'signature2');
      } else {
        validatePerson(this.riskProfileWrapper.person1, 'place1', 'signature1');
        validatePerson(this.riskProfileWrapper.person2, 'place1', 'signature1'); 
      }

      // If form is not valid, scroll to the first error
      if (!isValid) {
        this.$nextTick(() => {
          const firstError = document.querySelector('.error-message');
          if (firstError) {
            firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }

      return isValid;
    },

    /*getPersonName() {
      const { person1, person2, isRiskProfileForPerson2 } = this.riskProfileWrapper;

      if (this.isPerson2) {
        return `${person1.firstname} ${person1.lastname} & ${person2.firstname} ${person2.lastname}`;
      }

      // Determine which person's name to return
      const person = isRiskProfileForPerson2 ? person2 : person1;
      return `${person.firstname} ${person.lastname}`;
    },*/


  }


}
</script>

<style scoped>

p {
    text-align: justify;
    margin-bottom:6pt;
    margin-top:6pt;
}

.padding-table {
  padding-top:18px;
  padding-bottom:18px;
  padding-left:7px;
  padding-right:7px;
}

.padding-top{
  padding-top: 10px;
}

.text-bold {
  font-weight: bold;
}

.text-align-left {
  text-align: left;
  border: 2px solid rgb(199, 197, 197);
  padding-left: 5px;
}

.text-align-center {
  text-align: center;
  border: 2px solid rgb(199, 197, 197);
}

.text-align-right {
  text-align: right;
  border: 2px solid rgb(199, 197, 197);
  padding-right: 5px;
}

.selected-value-style {
  /*color: #202b91;*/
  color: #BF6849;
  font-weight: bold;
}

.selected-value-style2{
    color:#BF6849;
}

input[type="text"] {
  background-color: #ffffd5; /* Light yellow background */
  border: none; /* Remove default border */
  border-bottom: 2px solid #333; /* Add bottom border */
  padding: 2px; /* Add some padding */
  outline: none; /* Remove outline on focus */
  border-radius: 0px;
  width: 50%;
  margin: 5px 0px;
}

input[type="text"]:focus {
  background-color: #ffffe0; /* Slightly darker yellow on focus */
}


#app, #app * {
  box-sizing: content-box;
}

/*app {

}*/

.section-padding {
  padding-top: 0.25in;
}


.page-container {
  width: 100%;
  max-width: 27cm;
  overflow-x: visible;
  margin: auto;
  }


.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px solid #d3d3d3;
  border-radius: 0px;
  background-color: white;
  page-break-after: always;
  position: relative;
  font-family: Arial, sans-serif;
  font-size:11.5pt;
}

@media screen and (max-width: 27cm) {
  .page-container {
    margin-left: 0;
    margin-right: 0;
  }

  .page {
    margin-left: 0;
    margin-right: 0;
    border: none;
    box-shadow: none;
  }
}


.page-header {
  padding: 0.9in 0in 0in 0in;
  /*background: #202b91;
  color: #ffffff;*/
  margin: -2cm;
  margin-bottom: 1cm;
}

.page-footer {
  /*display: flex;*/
  justify-content: space-between;
  text-align: right;
  font-size: 14px;
  color: #333;
  position: absolute;
  bottom: 0px;
  left: 0;
  padding-left: 8.75in;
  padding-bottom: 0.5in;
  font-size:9pt;
  font-family: Arial, sans-serif;
}


.page-footer span {
  padding: 32px;
}

.page-footer span:first-child {
  margin-right: auto;
}

.page-footer span:last-child {
  margin-left: 6.5in;
}

body {
  font-family: Avenir Next LT Pro, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  font-weight: 100;
}

.heading-font-style {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #000000
}


.button-container-space {
  width: 100%;

}

.button-container {
  background-color: #fdfbf6;
  z-index: 550;
  padding: 20px;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2)
}

button {
  background-color: #BF6849;
  border: none;
  color: white;

  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 48px;

}

button:hover {
  background-color: #8e4e37;
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.signature-box img {
  max-width: 100%;
  max-height: 100%;
}

.error-message {
  position: absolute;
  background-color: rgb(190, 104, 73);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8em;
  z-index: 500;
  white-space: nowrap;
  pointer-events: none; /* Allows clicking through the error message */
}


/* Optional: Add a subtle animation for error messages */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.error-message {
  animation: fadeIn 0.3s ease-in-out;
}


</style>