<template>

  <header class="header">
    <div class="logo">
      <!-- Add your logo here -->
      <img
          src="https://images.squarespace-cdn.com/content/v1/6650a04a5d3ab928a99581ef/96758329-f48b-4c87-a976-4a32becd9af8/MS+Wordmark.png?format=1500w"
          alt="Logo">
    </div>
  </header>

  <div class="ms-home container mt-4">

    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
    </div>


    <div v-else-if="error" class="alert alert-danger">
      {{ error }}
    </div>
    <template v-else-if="wizardData">
      <!--<h2 class="mb-4">Contract Wizard: {{ wizardData.contractType }}</h2>-->


      <SignContractSection
          :isContractSigned="wizardData.isContractSigned"
          :contractId="wizardData.contractId"
      />

      <!-- Risk Profile Sections -->
      <template v-if="wizardData.contractType === 'Family (Two Person) - Two Risk Profiles'">
        <div class="row">
          <div class="col-md-6 mb-3" v-for="(profile, index) in ['riskProfile1', 'riskProfile2']" :key="profile">
            <!--<h4>Person {{ index + 1 }}</h4>-->
            <h4>{{ getPersonName(index + 1) }}</h4>

            <CalculateRiskProfileSection
                :isContractSigned="wizardData.isContractSigned"
                :isQuizCompleted="wizardData[profile].isQuizCompleted"
                :riskProfileId="wizardData[profile].riskProfileId"
            />

            <SignRiskProfileReportSection
                :isContractSigned="wizardData.isContractSigned"
                :isQuizCompleted="wizardData[profile].isQuizCompleted"
                :isReportSigned="wizardData[profile].isReportSigned"
                :riskProfileId="wizardData[profile].riskProfileId"
            />

          </div>
        </div>
      </template>

      <template v-else>
        <!-- Single Risk Profile Sections -->
        <CalculateRiskProfileSection
            :isContractSigned="wizardData.isContractSigned"
            :isQuizCompleted="wizardData.riskProfile1.isQuizCompleted"
            :riskProfileId="wizardData.riskProfile1.riskProfileId"
        />
        <SignRiskProfileReportSection
            :isContractSigned="wizardData.isContractSigned"
            :isQuizCompleted="wizardData.riskProfile1.isQuizCompleted"
            :isReportSigned="wizardData.riskProfile1.isReportSigned"
            :riskProfileId="wizardData.riskProfile1.riskProfileId"
        />
      </template>
    </template>
  </div>
</template>

<script>
import {ref, onMounted, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import axios from 'axios';
import {library} from '@fortawesome/fontawesome-svg-core';
import SignContractSection from "@/components/SignContractSection.vue";
import CalculateRiskProfileSection from "@/components/CalculateRiskProfileSection.vue";
import SignRiskProfileReportSection from "@/components/SignRiskProfileReportSection.vue";
import {faCheckCircle, faExclamationCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';

library.add(faCheckCircle, faExclamationCircle, faSpinner);

export default {
  name: 'ContractWizard',
  components: {
    FontAwesomeIcon, SignContractSection, CalculateRiskProfileSection, SignRiskProfileReportSection
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const wizardData = ref(null);
    const isLoading = ref(true);
    const error = ref(null);

    const fetchData = async () => {
      const recordId = route.query.id;
      if (!recordId) {
        error.value = 'No record ID provided in the URL.';
        isLoading.value = false;
        return;
      }

      try {
        isLoading.value = true;
        error.value = null;
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const endpoint = `${baseUrl}/contract-wizard?id=${recordId}`;


        const response = await axios.get(endpoint);

        if (response.data && response.data.data) {
          console.log(response);
          wizardData.value = response.data.data;
          console.log(wizardData.value);
        } else {
          wizardData.value = response.data;
        }
      } catch (err) {
        console.error('Error fetching wizard data:', err);
        error.value = 'Failed to fetch wizard data. Please try again later.';
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(fetchData);

    // Re-fetch data if the query parameter changes
    watch(() => route.query.id, (newId, oldId) => {
      if (newId !== oldId) {
        fetchData();
      }
    });

    const getStatusIcon = (isCompleted) => {
      return isCompleted ? ['fas', 'check-circle'] : ['fas', 'exclamation-circle'];
    };

    const getIconClass = (isCompleted) => {
      return isCompleted ? 'text-success' : 'text-warning';
    };

    const getStatusText = (isCompleted) => {
      return isCompleted ? 'Completed' : 'Not Started';
    };

    const getButtonText = (isCompleted, title) => {
      return isCompleted ? 'Completed' : `Go to ${title}`;
    };

    const getButtonClass = (isCompleted) => {
      return isCompleted
          ? 'btn-secondary cursor-not-allowed'
          : 'btn-primary';
    };

    const handleClick = (event, isCompleted) => {
      if (isCompleted) {
        event.preventDefault();
      }
    };

    const handleSignContractClick = (event, isCompleted, contractId) => {
      if (isCompleted) {
        event.preventDefault();
      } else {
        // Navigate programmatically
        router.push({path: '/sign-contract', query: {id: contractId}});
      }
    };

    return {
      wizardData,
      isLoading,
      error,
      getStatusIcon,
      getIconClass,
      getStatusText,
      getButtonText,
      getButtonClass,
      handleClick,
      handleSignContractClick
    };
  },

  methods: {
    getPersonName(index) {
      const personKey = `person${index}`;
      const person = this.wizardData[personKey];
      return person ? `${person.contactFirstname} ${person.contactLastName}` : 'Unknown Unknown';
    }
  }
};
</script>


<style>
.section-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.section-content {
  flex-grow: 1;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-bottom: 10px;
}

/*a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

a:hover {
  background-color: #0056b3;
}

a:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}*/

.status-icon {
  font-size: 18px;
  margin-left: 10px;
}

.completed {
  color: green;
}


.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #dc3545;
  margin-bottom: 20px;
}

.error-container .fa-exclamation-triangle {
  margin-right: 10px;
}


.draft {
  color: crimson;
}


h2 {
  color: #333;
  margin-bottom: 1.5rem;
}

.card {
  margin-bottom: 1.5rem;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.ms-btn {
  padding: 0.5rem 1rem;
  border-radius: 0px;
  background-color: #0000;
  color: #fff;
}

.ms-btn:hover {
  color: black;
  background: #fdfdfe;
}

/* Add these styles to all section components */
.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.overlay-content {
  text-align: center;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-content svg {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

.overlay-content p {
  font-size: 1rem;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.card {
  position: relative;
  overflow: hidden;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-success {
  color: #28a745;
}

.text-warning {
  color: #ffc107;
}

.text-secondary {
  color: #6c757d;
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.card {
  transition: background-color 0.3s ease;
  min-height: 200px; /* Set a minimum height for the card */
  border-radius: 0px;
}

.card-body {
  height: 100%; /* Ensure the card body takes up the full height of the card */
}

.text-white {
  color: #ffffff !important;
}

.text-tc {
  color: #BF6849 !important;
}

.fa-4x {
  font-size: 4em;
}

.bg-primary {
  background-color: #bf6849 !important;
}

.bg-success {
  background-color: #FFFCF7 !important;
}

.bg-secondary {
  background-color: #FFFCF7 !important;
}

.ms-btn {
  border-radius: 0px !important;
}

.ms-home {
  max-width: 800px;
}

</style>