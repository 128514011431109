<template>

  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  margin-bottom: 0px;
}

.logo {
  height: auto;
  max-height: 32px;
  width: auto;
  max-width: 100%;
}

.logo img {
  width: 100%;
  height: auto;
  max-height: 25px;
  object-fit: contain;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@import url('https://fonts.googleapis.com/css2?family=Marcellus:wght@400&family=Rubik:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700');

body {
  background-color: #F6F1EB !important;
  font-family: 'Rubik';
}



</style>

<script>


</script>
